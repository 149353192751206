<template>
  <el-container>
    <div class="loading-logo" v-if="loading">
      <div>
        <h3>加载中，请耐心等待……</h3>
      </div>
    </div>
    <div class="w-100 warpper" v-else>
      <div class="banner">
        <h1>海地总统刺杀</h1>
        <p><span>报告于2021-8-2 13:54:22自动生成</span></p>
      </div>
      <div class="btn_tab" id="emotion-tab">
        <div class="fxbg">
          <div class="warpper">
            <div class="flex-container">
              <a class="selected cur-p" @click="switchTab(0, true)">方案情感概述</a>
              <a class="cur-p" @click="switchTab(1, true)">总体信息情感媒体来源分析</a>
              <a class="cur-p" @click="switchTab(2, true)">总体信息情感声量趋势分析</a>
              <a class="cur-p" @click="switchTab(3, true)">负面信息剖析</a>
            </div>
          </div>
        </div>
      </div>
      <div class="fxbg pt_0">
        <!-- 方案情感概述 -->
        <a name="qggs" id="qggs"></a>
        <div class="warpper">
          <el-card class="box-card">
            <h3 class="mod-title">方案情感概述</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p :class="row.keyword.length > 1 ? 'paragraph' : 'paragraph lh-30'" v-for="(row, index) in data.summary" :key="index">
                <strong v-if="row.keyword">{{ row.keyword }}：</strong>
                <span v-if="!isArray(row.text)">{{ row.text }}</span>
                <span class="text-tag" v-for="(tag, index) in row.text" :key="index" v-else>{{ tag }}</span>
              </p>
            </div>
          </el-card>
        </div>
        <!-- 总体信息情感媒体来源分析 -->
        <a name="lyfx" id="lyfx"></a>
        <div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">总体信息情感媒体来源分析</h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <p class="paragraph" v-for="(row, index) in data.summary_em_dis" :key="index">
                  <strong v-if="row.keyword">{{ row.keyword }}：</strong>
                  <span v-if="!isArray(row.text)">{{ row.text }}</span>
                  <span class="text-tag" v-for="(tag, index) in row.text" :key="index" v-else>{{ tag }}</span>
                </p>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">网媒类型情绪分布</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_web_media'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="em_dis_for_web_media" v-show="data['em_dis_for_web_media'].data.length >= 1"></div>
                  </div>
                </el-card>
                <el-card class="box-card hide">
                  <h3 class="mod-title">总情绪占比</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty hide">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="em_por"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">各媒体类型情绪分布</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_cat'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="em_dis_for_cat" v-show="data['em_dis_for_cat'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">                
                <el-card class="box-card">
                  <h3 class="mod-title">微博用户类型情绪分布</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_weibo_user'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="em_dis_for_weibo_user" v-show="data['em_dis_for_weibo_user'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">微博类型情绪分布</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_weibo_type'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="em_dis_for_weibo_type" v-show="data['em_dis_for_weibo_type'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20 hide">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">整体信息情感日历</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_por_date'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="qgrl h-250">
                      <div class="left" v-show="calendarMonth.left.month">
                        <i class="el-icon-d-arrow-left" @click="calendarChart(calendarMonth.left, 'em_por_date')"></i><br>
                        <span>{{ calendarMonth.left.month }}</span>
                      </div>
                      <div class="h-250 qgrl-chart" id="em_por_date" v-show="data['em_por_date'].data.length >= 1"></div>
                      <div class="right" v-show="calendarMonth.right.month">
                        <i class="el-icon-d-arrow-right" @click="calendarChart(calendarMonth.right, 'em_por_date')"></i><br>
                        <span>{{ calendarMonth.right.month }}</span>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">所有媒体情绪分布图</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_media'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250 w-100" id="em_dis_for_media" v-show="data['em_dis_for_media'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">所有媒体情绪分布占比图</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_media_por'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="em_dis_for_media_por" v-show="data['em_dis_for_media_por'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 总体信息情感声量趋势分析 -->
        <a name="qsfx" id="qsfx"></a>
        <div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">总体信息情感声量趋势分析</h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <p class="paragraph" v-for="(row, index) in data.summary_em_tre" :key="index">
                  <strong v-if="row.keyword">{{ row.keyword }}：</strong>
                  <span v-if="!isArray(row.text)">{{ row.text }}</span>
                  <span class="text-tag" v-for="(tag, index) in row.text" :key="index" v-else>{{ tag }}</span>
                </p>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">总体信息情感走势</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_tre'].keys.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="em_tre" v-show="data['em_tre'].keys.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">网媒信息情感走势</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_tre_for_web_media'].keys.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="em_tre_for_web_media" v-show="data['em_tre_for_web_media'].keys.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">微博信息情感走势</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_tre_for_weibo'].keys.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="em_tre_for_weibo" v-show="data['em_tre_for_weibo'].keys.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">自媒体信息情感走势</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_tre_for_weixin'].keys.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="em_tre_for_weixin" v-show="data['em_tre_for_weixin'].keys.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20" style="display: none;">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">论坛情感走势</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty">
                      <div class="img"></div>
                    </div>
                    <div style="height:100px;background:red;display: none;"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">其他信息情感走势</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty">
                      <div class="img"></div>
                    </div>
                    <div style="height:100px;background:red;display: none;"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 总体信息地区情感分布 删除 -->
        <a name="qgfb" id="qgfb"></a>
        <div style="display: none;">
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">总体信息地区情感分布</h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <p class="paragraph">从各地区的情感走势来看，[地区]的负面信息总量最多，[地区]的正面信息总量最多。[地区]的负面信息占比最高，[地区]的正面信息占比最高。</p>
                <p class="paragraph">而随着时间走势来看，信息总量排名前10名的地区中，[地区1、地区2...没有地区是]有负面信息发布时间集中的趋势，[地区1、地区2...没有地区是]有正面信息发布时间集中的趋势。</p>
                <p class="paragraph">负面信息高峰点发生于[地区][时间]，正面信息高峰点发生于[地区][时间]。</p>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">总体信息地区情感分布</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty">
                      <div class="img"></div>
                    </div>
                    <div style="height:100px;background:red;display: none;"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">总体信息地区情感占比分布</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty">
                      <div class="img"></div>
                    </div>
                    <div style="height:100px;background:red;display: none;"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">总体信息地区情感趋势</h3>
              <div class="pad-lr-20">
                <div class="echarts_empty">
                  <div class="img"></div>
                </div>
                <div style="height:100px;background:red;display: none;"></div>
              </div>
            </el-card>
          </div>
        </div>
        <!-- 负面信息剖析 -->
        <a name="fmxxpx" id="fmxxpx"></a>
        <div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">负面信息剖析</h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <p class="paragraph">{{ data.summary_em_neg.length >= 1 ? data.summary_em_neg[0].text : "" }}</p>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面情绪媒体类型分布</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_dis_for_cat'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="neg_dis_for_cat" v-show="data['neg_dis_for_cat'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面情绪网媒类型分布</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_dis_for_web_media'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="neg_dis_for_web_media" v-show="data['neg_dis_for_web_media'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面情绪微博类型分布</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_dis_for_weibo_type'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="neg_dis_for_weibo_type" v-show="data['neg_dis_for_weibo_type'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面情绪微博用户类型分布</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_dis_for_weibo_user'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="neg_dis_for_weibo_user" v-show="data['neg_dis_for_weibo_user'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面信息媒体分布<span class="red">Top10</span>媒体</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_num_top.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_red progress_no_bg h-386" v-show="data.neg_num_top.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">媒体</span><span class="tag f-r">条数</span></h3>
                        </li>
                        <li v-for="(data, index) in data.neg_num_top" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-if="index == 1">2</span>
                          <span class="num bg-8ea" v-if="index == 2">3</span>
                          <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :title="data.name">{{ data.name }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="data.rate" :show-text="false"></el-progress>
                          <span class="tag">{{ data.total }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面信息占比<span class="red">Top10</span>媒体</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_por_top.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_red h-386" v-show="data.neg_por_top.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">媒体</span><span class="tag f-r">占比</span></h3>
                        </li>
                        <li v-for="(data, index) in data.neg_por_top" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-if="index == 1">2</span>
                          <span class="num bg-8ea" v-if="index == 2">3</span>
                          <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :title="data.name">{{ data.name }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="data.percent" :show-text="false"></el-progress>
                          <span class="tag">{{ data.percent }}%</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20 hide">
            <el-card class="box-card">
              <h3 class="mod-title">总体负面信息总体趋势</h3>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data['neg_tre_date'].data.length < 1">
                  <div class="img"></div>
                </div>
                <div class="w-100 h-550" id="neg_tre_date" v-show="data['neg_tre_date'].data.length >= 1"></div>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">总体负面信息情感走势</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_tre'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="neg_tre" v-show="data['neg_tre'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">网媒负面信息情感走势</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_tre_for_web_media'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="neg_tre_for_web_media" v-show="data['neg_tre_for_web_media'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">微博负面情感走势</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="!data['neg_tre_for_weibo_type'].keys">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="neg_tre_for_weibo_type" v-show="data['neg_tre_for_weibo_type'].keys"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">微博用戶负面情感走势</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_tre_for_weibo_user'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="neg_tre_for_weibo_user" v-show="data['neg_tre_for_weibo_user'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card fmxxnrgd">
                  <h3 class="mod-title">负面信息内容观点</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_op.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_red progress_no_bg h-396" v-show="data.neg_op.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">观点</span><span class="tag">占比</span><span class="tag">条数</span></h3>
                        </li>
                        <li v-for="(data, index) in data.neg_op" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-if="index == 1">2</span>
                          <span class="num bg-8ea" v-if="index == 2">3</span>
                          <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="data.source_url" target="_blank" :title="data.view">{{ data.view }}</a>
                          <span class="tag">{{ data.rate }}%</span>
                          <span class="tag">{{ data.total }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card echarts-box">
                  <h3 class="mod-title">全部负面信息热门关键词</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_key.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-100 ov-h mar-t-30 flex" v-show="data.neg_key.length >= 1">
                      <div class="mod-list">
                        <ul>
                          <li v-for="(data, index) in data.neg_key" :key="index" v-if="index < 10">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-if="index == 1">2</span>
                            <span class="num bg-8ea" v-if="index == 2">3</span>
                            <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="data.keyword">{{ data.keyword }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="h-350 mod-echarts-right" id="neg_key"></div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <!-- 不是事件 -->
            <el-card class="box-card" v-if="!data['pos_vs_neg'].isIncubation">
              <h3 class="mod-title">负面信息观点趋势变化</h3>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data.neg_op_tre.keys.length < 1">
                  <div class="img"></div>
                </div>
                <!-- 时间轴 -->
                <div class="demo" v-show="data.neg_op_tre.keys.length >= 1">
                  <div class="main-timeline">
                    <div class="timeline" v-for="(time, index) in data.neg_op_tre.keys" :key="index">
                      <div class="timeline-content">
                        <div class="circle">
                          <div class="mod-list">
                            <ul>
                              <li v-for="(data, sub_index) in data.neg_op_tre.data[index]" :key="sub_index" v-if="sub_index < 3">
                                <span class="num" v-if="sub_index == 0">1</span>
                                <span class="num bg_ffb" v-if="sub_index == 1">2</span>
                                <span class="num bg_ec7" v-if="sub_index == 2">3</span>
                                <a class="title" :href="data.source_url" target="_blank" :title="data.title">{{ data.title }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="content">
                          <span class="year">{{ time }}</span>
                          <div class="icon"><span></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            <!-- 是事件 -->
            <el-card class="box-card" v-else>
              <h3 class="mod-title">正负观点趋势碰撞</h3>
              <div class="pad-lr-20">
                <div class="w-100 h-400" id="pos_vs_neg_trend">
                </div>
                <div class="echarts_empty hide">
                  <div class="img"></div>
                </div>
                <div class="pk1" v-if="data['pos_vs_neg'].pos_weight > data['pos_vs_neg'].neg_weight && data['pos_vs_neg'].pos_neg_diff > 10">
                  <p class="zm">重要评分：{{ data['pos_vs_neg'].pos_weight }}</p>
                  <p class="fm">重要评分：{{ data['pos_vs_neg'].neg_weight }}</p>
                </div>
                <div class="pk2" v-if="data['pos_vs_neg'].neg_weight > data['pos_vs_neg'].pos_weight && data['pos_vs_neg'].pos_neg_diff > 10">
                  <p class="zm">重要评分：{{ data['pos_vs_neg'].pos_weight }}</p>
                  <p class="fm">重要评分：{{ data['pos_vs_neg'].neg_weight }}</p>
                </div>
                <div class="pk3" v-if="data['pos_vs_neg'].pos_neg_diff <= 10">
                  <p class="zm">重要评分：{{ data['pos_vs_neg'].pos_weight }}</p>
                  <p class="fm">重要评分：{{ data['pos_vs_neg'].neg_weight }}</p>
                </div>
                <!-- 时间轴 -->
                <div class="demo2">
                  <div class="main-timeline2" v-for="(row, index) in data['pos_vs_neg'].timeline" :key="index">
                    <div class="item" v-if="row.type === 'point'">
                      <div class="timeline">
                        <div class="timeline-content">
                          <div class="circle">
                            <div class="timeline_title">
                              <img src="../../../assets/images/analysis/sheng_h.svg" alt="胜" class="img" width="25" v-if="row.weight_neg < row.weight_pos" />
                              <h4><a :href="row.article_pos.source_url" target="_blank">{{ row.article_pos.title }}</a></h4>
                              <p><span>相关报导数：{{ row.article_pos.total }}</span><span>信息占比：{{ row.article_pos.rate }}%</span></p>
                              <dl>
                                <dd v-for="(media, index) in row.article_pos.medias" :key="index">
                                  <a>{{ media }}</a><span class="bd">|</span>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="content">
                        <span class="year">{{ row.date }}</span>
                        <div class="point">
                          <p class="start" v-for="(point, index) in row.points" :key="index">
                            <img :src="findPointImg(point)" :alt="point" width="18" type="image/svg+xml" />
                            <span>{{ point }}</span>
                          </p>
                        </div>
                      </div>
                      <div class="timeline">
                        <div class="timeline-content">
                          <div class="circle">
                            <div class="timeline_title">
                              <img src="../../../assets/images/analysis/sheng_l.svg" alt="胜" class="img" width="25" v-if="row.weight_neg > row.weight_pos" />
                              <h4><a :href="row.article_neg.source_url" target="_blank">{{ row.article_neg.title }}</a></h4>
                              <p><span>相关报导数：{{ row.article_neg.total }}</span><span>信息占比：{{ row.article_neg.rate }}%</span></p>
                              <dl>
                                <dd v-for="(media, index) in row.article_neg.medias" :key="index">
                                  <a>{{ media }}</a><span class="bd">|</span>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item item_list" v-else>
                      <div class="timeline">
                        <div class="timeline-content">
                          <div class="circle">
                            <div class="mod-list">
                              <img src="../../../assets/images/analysis/sheng_h.svg" alt="胜" class="img" width="25" v-if="row.weight_neg < row.weight_pos" />
                              <ul>
                                <li v-for="(row, index) in row.articles_pos" :key="index">
                                  <span class="num bg-555" v-if="index == 0">1</span>
                                  <span class="num bg-689" v-else-if="index == 1">2</span>
                                  <span class="num bg-8ea" v-else-if="index == 2">3</span>
                                  <a :href="row.source_url" target="_blank" class="title">{{ row.title }}</a>
                                  <a class="site"><span>|</span>{{ row.media }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="content">
                        <h5>{{ row.state }}</h5>
                      </div>
                      <div class="timeline">
                        <div class="timeline-content">
                          <div class="circle">
                            <div class="mod-list">
                              <img src="../../../assets/images/analysis/sheng_l.svg" alt="胜" class="img" width="25" v-if="row.weight_neg > row.weight_pos" />
                              <ul>
                                <li v-for="(row, index) in row.articles_neg" :key="index">
                                  <span class="num bg-555" v-if="index == 0">1</span>
                                  <span class="num bg-689" v-else-if="index == 1">2</span>
                                  <span class="num bg-8ea" v-else-if="index == 2">3</span>
                                  <a :href="row.source_url" target="_blank" class="title">{{ row.title }}</a>
                                  <a class="site"><span>|</span>{{ row.media }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20 hide">
            <el-card class="box-card">
              <h3 class="mod-title">负面信息关键字趋势变化</h3>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data['neg_key_tre'].keys.length < 1">
                  <div class="img"></div>
                </div>
                <div class="w-100 h-380" id="neg_key_tre" v-show="data['neg_key_tre'].keys.length >= 1"></div>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card echarts-box">
                  <h3 class="mod-title">网媒负面信息热门关键词</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_key_for_web_media.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-100 ov-h flex" v-show="data.neg_key_for_web_media.length >= 1">
                      <div class="mod-list">
                        <ul>
                          <li v-for="(data, index) in data.neg_key_for_web_media" :key="index" v-if="index <= 9">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-if="index == 1">2</span>
                            <span class="num bg-8ea" v-if="index == 2">3</span>
                            <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="data.keyword">{{ data.keyword }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="h-350 mod-echarts-right" id="neg_key_for_web_media"></div>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card echarts-box">
                  <h3 class="mod-title">微博负面信息热门关键词</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_key_for_weibo.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-100 ov-h flex" v-show="data.neg_key_for_weibo.length >= 1">
                      <div class="mod-list">
                        <ul>
                          <li v-for="(data, index) in data.neg_key_for_weibo" :key="index" v-if="index <= 9">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-if="index == 1">2</span>
                            <span class="num bg-8ea" v-if="index == 2">3</span>
                            <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="data.keyword">{{ data.keyword }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="h-350 mod-echarts-right" id="neg_key_for_weibo"></div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20 hide">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card echarts-box">
                  <h3 class="mod-title">微信负面信息热门关键词</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_key_for_weixin.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-100 ov-h flex" v-show="data.neg_key_for_weixin.length >= 1">
                      <div class="mod-list">
                        <ul>
                          <li v-for="(data, index) in data.neg_key_for_weixin" :key="index" v-if="index <= 9">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-if="index == 1">2</span>
                            <span class="num bg-8ea" v-if="index == 2">3</span>
                            <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="data.keyword">{{ data.keyword }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="h-350 mod-echarts-right" id="neg_key_for_weixin"></div>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card echarts-box">
                  <h3 class="mod-title">其他来源负面信息热门关键词</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_key_for_forum.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-100 ov-h flex" v-show="data.neg_key_for_forum.length >= 1">
                      <div class="mod-list">
                        <ul>
                          <li v-for="(data, index) in data.neg_key_for_forum" :key="index" v-if="index <= 9">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-if="index == 1">2</span>
                            <span class="num bg-8ea" v-if="index == 2">3</span>
                            <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="data.keyword">{{ data.keyword }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="h-350 mod-echarts-right" id="neg_key_for_forum"></div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script type="application/javascript">
import {
  findPointImg,
  globalCreateChart,
  globalGetEcharts,
  globalMediaColor
} from '@/utils/helpers.js'
export default {
  name: 'analysis-emotion',
  data() {
    return {
      title: '',
      data: {
        em_por: {
          negative: 190607,
          positive: 65237
        },
        em_dis_for_cat: {
          type: ['微博', '网媒', '微信', '论坛', '政务', '博客', '报刊'],
          data: [
            {
              negative: 73.7,
              positive: 26.3
            },
            {
              negative: 83.5,
              positive: 16.5
            },
            {
              negative: 81,
              positive: 19
            },
            {
              negative: 86.9,
              positive: 13.1
            },
            {
              negative: 86.7,
              positive: 13.3
            },
            {
              negative: 69.2,
              positive: 30.8
            },
            {
              negative: 100,
              positive: 0
            }
          ]
        },
        em_dis_for_web_media: {
          type: ['一般媒体', '官媒', '国外媒体'],
          data: [
            {
              negative: 74.5,
              positive: 25.5
            },
            {
              negative: 78.8,
              positive: 21.2
            },
            {
              negative: 80,
              positive: 20
            }
          ]
        },
        em_dis_for_weibo_type: {
          type: ['转发', '原发'],
          data: [
            {
              negative: 76.8,
              positive: 23.2
            },
            {
              negative: 39.5,
              positive: 60.5
            }
          ]
        },
        em_dis_for_weibo_user: {
          type: ['普通用户', '达人', '黄V', '蓝V', '金V', '微博女郎'],
          data: [
            {
              negative: 74.4,
              positive: 25.6
            },
            {
              negative: 77.7,
              positive: 22.3
            },
            {
              negative: 69.7,
              positive: 30.3
            },
            {
              negative: 49.8,
              positive: 50.2
            },
            {
              negative: 60.6,
              positive: 39.4
            },
            {
              negative: 80.6,
              positive: 19.4
            }
          ]
        },
        em_por_date: {
          type: ['2019-07'],
          data: [
            {
              date: '2019-07-01',
              total: 8,
              negative: 100,
              positive: 0
            },
            {
              date: '2019-07-02',
              total: 21,
              negative: 81,
              positive: 19
            },
            {
              date: '2019-07-03',
              total: 80244,
              negative: 77.1,
              positive: 22.9
            },
            {
              date: '2019-07-04',
              total: 67253,
              negative: 58,
              positive: 42
            },
            {
              date: '2019-07-05',
              total: 20664,
              negative: 62.1,
              positive: 37.9
            },
            {
              date: '2019-07-06',
              total: 58690,
              negative: 92.1,
              positive: 7.9
            },
            {
              date: '2019-07-07',
              total: 16552,
              negative: 85.7,
              positive: 14.3
            },
            {
              date: '2019-07-08',
              total: 7472,
              negative: 68.7,
              positive: 31.3
            },
            {
              date: '2019-07-09',
              total: 4940,
              negative: 70.7,
              positive: 29.3
            }
          ]
        },
        em_dis_for_media: {
          type: ['微信', '微博', '网媒'],
          data: [
            [
              {
                name: '财经网',
                value: [15, 20]
              },
              {
                name: '21世纪经济报道',
                value: [14, 16]
              },
              {
                name: '经济观察报',
                value: [24, 6]
              },
              {
                name: '财经杂志',
                value: [17, 8]
              },
              {
                name: '券商中国',
                value: [18, 5]
              },
              {
                name: '南国早报',
                value: [3, 16]
              },
              {
                name: '凤凰网房产',
                value: [9, 5]
              },
              {
                name: '广州日报',
                value: [2, 11]
              },
              {
                name: '腾讯财讯',
                value: [5, 8]
              },
              {
                name: '热点事扒',
                value: [2, 10]
              },
              {
                name: '深蓝财经',
                value: [3, 8]
              },
              {
                name: '腾讯房产长沙站',
                value: [7, 4]
              },
              {
                name: '上游新闻',
                value: [0, 9]
              },
              {
                name: '中国经营报',
                value: [1, 8]
              },
              {
                name: '新京报动新闻',
                value: [2, 7]
              },
              {
                name: '21财闻汇',
                value: [0, 8]
              },
              {
                name: '中国妇女报',
                value: [4, 4]
              },
              {
                name: '券业行家',
                value: [6, 2]
              },
              {
                name: '每日经济新闻',
                value: [2, 6]
              },
              {
                name: '万象评论APP',
                value: [3, 4]
              },
              {
                name: '上海评论APP',
                value: [1, 6]
              },
              {
                name: '中经城事',
                value: [2, 5]
              },
              {
                name: '北京评论APP',
                value: [0, 7]
              },
              {
                name: '封面新闻',
                value: [1, 6]
              },
              {
                name: '广西新闻频道',
                value: [0, 7]
              },
              {
                name: '腾讯财经',
                value: [1, 6]
              },
              {
                name: '警界那些事儿',
                value: [0, 7]
              },
              {
                name: '财经读吧',
                value: [4, 2]
              },
              {
                name: '趣阅读啊',
                value: [2, 4]
              },
              {
                name: '896汽车调频',
                value: [0, 5]
              },
              {
                name: 'FM93交通之声',
                value: [0, 5]
              },
              {
                name: 'e公司官微',
                value: [1, 4]
              },
              {
                name: '五年十倍',
                value: [2, 3]
              },
              {
                name: '大律live',
                value: [0, 5]
              },
              {
                name: '宿州楼王',
                value: [1, 4]
              },
              {
                name: '新闻头条',
                value: [1, 4]
              },
              {
                name: '时代周报',
                value: [3, 2]
              },
              {
                name: '经济日报',
                value: [2, 3]
              },
              {
                name: '长江日报',
                value: [2, 3]
              },
              {
                name: 'CFO智库',
                value: [0, 4]
              },
              {
                name: 'CNAF',
                value: [2, 2]
              },
              {
                name: 'WM走进新时代',
                value: [0, 4]
              },
              {
                name: '中国基金报',
                value: [1, 3]
              },
              {
                name: '中国新闻网',
                value: [0, 4]
              },
              {
                name: '久历风尘',
                value: [0, 4]
              },
              {
                name: '企业家智慧',
                value: [3, 1]
              },
              {
                name: '凤凰网股票',
                value: [2, 2]
              },
              {
                name: '天涯连线',
                value: [1, 3]
              },
              {
                name: '当代商报楼宇报道',
                value: [4, 0]
              },
              {
                name: '成都商报',
                value: [0, 4]
              }
            ],
            [
              {
                name: '简wanxin',
                value: [0, 277]
              },
              {
                name: '陈磊磊William',
                value: [74, 64]
              },
              {
                name: '二丶然然',
                value: [61, 60]
              },
              {
                name: '偷偷的玩一会微博',
                value: [41, 74]
              },
              {
                name: '秦桂江',
                value: [101, 6]
              },
              {
                name: '一度风雨一江春',
                value: [54, 49]
              },
              {
                name: '金沙江畔2019',
                value: [2, 101]
              },
              {
                name: '彩色蝈蝈叫',
                value: [19, 67]
              },
              {
                name: '高中18473775905',
                value: [0, 85]
              },
              {
                name: '猫wo打哈欠',
                value: [57, 22]
              },
              {
                name: '雪球',
                value: [66, 12]
              },
              {
                name: '小猫咪玩自拍',
                value: [0, 73]
              },
              {
                name: '水晶冰儿zy',
                value: [35, 36]
              },
              {
                name: '改改Letitgo',
                value: [37, 29]
              },
              {
                name: 'dww叶子',
                value: [2, 57]
              },
              {
                name: 'ACMILAN裔天',
                value: [27, 31]
              },
              {
                name: '新浪证券',
                value: [41, 16]
              },
              {
                name: '大梁子1713',
                value: [32, 24]
              },
              {
                name: '楼西满月时回子燕',
                value: [16, 30]
              },
              {
                name: '阿莉是个坚强的女孩纸',
                value: [1, 41]
              },
              {
                name: '工程上那些事儿',
                value: [9, 29]
              },
              {
                name: '胖圈圈要变瘦吉吉',
                value: [20, 17]
              },
              {
                name: '新浪财经',
                value: [24, 11]
              },
              {
                name: '渠某某siwo',
                value: [22, 12]
              },
              {
                name: '财经网',
                value: [23, 11]
              },
              {
                name: 'ikimi',
                value: [18, 15]
              },
              {
                name: '温柔随性的朋友',
                value: [33, 0]
              },
              {
                name: '曹保印啦啦啦',
                value: [18, 14]
              },
              {
                name: '华盛热门',
                value: [22, 9]
              },
              {
                name: '咫尺天涯12369',
                value: [0, 29]
              },
              {
                name: '看世界49704',
                value: [13, 15]
              },
              {
                name: '松深深',
                value: [10, 17]
              },
              {
                name: '凤凰网财经',
                value: [11, 15]
              },
              {
                name: '孟晖的蔷薇苑',
                value: [9, 17]
              },
              {
                name: '搞笑桓',
                value: [26, 0]
              },
              {
                name: '每夜都为绝美爱情独自垂泪',
                value: [11, 15]
              },
              {
                name: '每日经济新闻',
                value: [16, 10]
              },
              {
                name: '聚满物流',
                value: [8, 17]
              },
              {
                name: '习无若',
                value: [5, 19]
              },
              {
                name: '镜--织梦者',
                value: [9, 15]
              },
              {
                name: 'First70437',
                value: [0, 23]
              },
              {
                name: '把不开心挤掉',
                value: [23, 0]
              },
              {
                name: 'KILLTHEBULE',
                value: [14, 8]
              },
              {
                name: '投资1999',
                value: [9, 13]
              },
              {
                name: 'AI财经社',
                value: [13, 8]
              },
              {
                name: '你咋着皮',
                value: [11, 9]
              },
              {
                name: '台州吴健',
                value: [15, 5]
              },
              {
                name: '斯佳丽1840',
                value: [9, 11]
              },
              {
                name: '许魏洲的惹火腿毛',
                value: [12, 8]
              },
              {
                name: '一路顺风快乐',
                value: [0, 19]
              }
            ],
            [
              {
                name: '东方财富网',
                value: [103, 337]
              },
              {
                name: 'ZAKER',
                value: [49, 373]
              },
              {
                name: '新浪网',
                value: [51, 246]
              },
              {
                name: '潮州人新闻网',
                value: [37, 237]
              },
              {
                name: '海泡新闻网',
                value: [33, 210]
              },
              {
                name: '鹏洋网络',
                value: [5, 177]
              },
              {
                name: '中国经济网',
                value: [17, 161]
              },
              {
                name: '网易',
                value: [28, 147]
              },
              {
                name: '乐居',
                value: [69, 105]
              },
              {
                name: '慈溪新闻网',
                value: [21, 151]
              },
              {
                name: '金融界',
                value: [21, 139]
              },
              {
                name: '上游新闻',
                value: [8, 148]
              },
              {
                name: '和讯网',
                value: [30, 126]
              },
              {
                name: '凤凰网',
                value: [26, 110]
              },
              {
                name: '搜狐',
                value: [20, 111]
              },
              {
                name: '投资之家',
                value: [28, 101]
              },
              {
                name: '腾讯网',
                value: [26, 96]
              },
              {
                name: '财经新闻网',
                value: [36, 83]
              },
              {
                name: '中国小康网',
                value: [2, 105]
              },
              {
                name: '交通信息网',
                value: [21, 86]
              },
              {
                name: '中国网',
                value: [16, 75]
              },
              {
                name: '股城网',
                value: [9, 80]
              },
              {
                name: '一本财经网',
                value: [51, 36]
              },
              {
                name: '笑奇网',
                value: [8, 77]
              },
              {
                name: '依安在线',
                value: [12, 65]
              },
              {
                name: '马蹄新闻',
                value: [5, 71]
              },
              {
                name: '汉丰网',
                value: [15, 58]
              },
              {
                name: '平湖新闻',
                value: [2, 69]
              },
              {
                name: '每经网',
                value: [13, 58]
              },
              {
                name: '爱财经新闻网',
                value: [15, 56]
              },
              {
                name: '大众网',
                value: [1, 69]
              },
              {
                name: '中金在线',
                value: [10, 59]
              },
              {
                name: '海吉斯',
                value: [9, 59]
              },
              {
                name: '红歌会网',
                value: [13, 50]
              },
              {
                name: '中华网河北',
                value: [3, 57]
              },
              {
                name: '沃财富',
                value: [7, 52]
              },
              {
                name: '股市热点',
                value: [16, 43]
              },
              {
                name: 'fx112财经网',
                value: [17, 40]
              },
              {
                name: '搜狐焦点',
                value: [0, 57]
              },
              {
                name: '新京报网',
                value: [10, 47]
              },
              {
                name: '游侠股市',
                value: [12, 45]
              },
              {
                name: '黄山新闻',
                value: [4, 52]
              },
              {
                name: '人民网',
                value: [0, 53]
              },
              {
                name: '云掌财经',
                value: [20, 32]
              },
              {
                name: '西坡在线',
                value: [2, 50]
              },
              {
                name: '手机新浪网',
                value: [21, 29]
              },
              {
                name: '688财经网',
                value: [24, 25]
              },
              {
                name: 'A看点网',
                value: [4, 44]
              },
              {
                name: '机器网',
                value: [4, 44]
              },
              {
                name: '百度',
                value: [6, 40]
              }
            ]
          ]
        },
        em_dis_for_media_por: {
          type: ["微信","微博","网媒"],
          data: [
          [
              {name: "参考消息",value: [0,100]},        
              {name: "央视财经",value: [0,100]},
              {name: "正能量名录",value: [0,100]}
          ],
          [
              {name: "姬博露",value: [35.5,64.5]},
          {name: "围脖电影院",value: [88.9,11.1]},
          {name: "心碎葬海丶宾康",value: [100,0]},
          {name: "精选视频集锦",value: [91.3,8.7]},
          {name: "四川魏静",value: [72.2,27.8]},
          {name: "电影全搜罗",value: [100,0]},
          {name: "popper123123",value: [75,25]},
          {name: "可怜无数山2",value: [46.7,53.3]},
          {name: "慕男军情",value: [86.7,13.3]},
          {name: "2003执子之手",value: [76.9,23.1]},
          {name: "Rabbit每日扒料",value: [92.3,7.7]},
          {name: "湛泸剑",value: [41.7,58.3]},
          {name: "微天下",value: [100,0]},
          {name: "整点星闻",value: [100,0]},
          {name: "长歪了的螺丝钉",value: [81.8,18.2]},
          {name: "井主心下满",value: [100,0]},
          {name: "军威长风",value: [60,40]},
          {name: "奶糖味小俊_",value: [100,0]},
          {name: "张哲瀚的欢翎晗间",value: [0,100]},
          {name: "用户7648272795",value: [100,0]},
          {name: "纪田墨",value: [100,0]},
          {name: "金哥噢",value: [80,20]},
          {name: "liusong的美丽家园",value: [33.3,66.7]},
          {name: "吃瓜小喇叭",value: [88.9,11.1]},
          {name: "恩恩有个公主梦7",value: [100,0]},
          {name: "柔情的主义",value: [100,0]},
          {name: "西子丹聊斋",value: [44.4,55.6]},
          {name: "Niiiiiicer",value: [87.5,12.5]},
          {name: "人民日报海外版-海外网",value: [100,0]},
          {name: "娱事抢先看",value: [100,0]},
          {name: "曜记得浇水芽",value: [100,0]},
          {name: "熊人的那些事_G1级",value: [75,25]},
          {name: "赠送小说阅读器",value: [100,0]},
          {name: "rlirli",value: [100,0]},
          {name: "井中天空中云",value: [57.1,42.9]},
          {name: "喜歡兔叽·",value: [85.7,14.3]},
          {name: "围脖视频",value: [100,0]},
          {name: "微微万象民生直通车A",value: [85.7,14.3]},
          {name: "扒妹一米五",value: [71.4,28.6]},
          {name: "新西兰先驱报中文网",value: [28.6,71.4]},
          {name: "星弧传媒",value: [71.4,28.6]},
          {name: "杰客七",value: [57.1,42.9]},
          {name: "水晶公主的护卫",value: [71.4,28.6]},
          {name: "湖北新闻",value: [85.7,14.3]},
          {name: "用户7629558000",value: [14.3,85.7]},
          {name: "超级毛毛虫L",value: [85.7,14.3]},
          {name: "陕西财经大学",value: [71.4,28.6]},
          {name: "香港衛視HKS",value: [100,0]},
          {name: "俄罗斯卫星通讯社",value: [83.3,16.7]},
          {name: "十八子91221",value: [66.7,33.3]}],[{name: "搜狐",value: [13.1,86.9]},
          {name: "网易",value: [17.4,82.6]},
          {name: "新浪网",value: [20.5,79.5]},
          {name: "百度",value: [33.8,66.2]},
          {name: "南京澎湃文化传媒有限公司",value: [11.9,88.1]},
          {name: "贴吧",value: [27.2,72.8]},
          {name: "中评网",value: [14.3,85.7]},
          {name: "中华网河北",value: [29,71]},
          {name: "四海网",value: [52,48]},
          {name: "东方网",value: [12.5,87.5]},
          {name: "凤凰网",value: [16.9,83.1]},
          {name: "和讯网",value: [66.7,33.3]},
          {name: "代生网",value: [15.2,84.8]},
          {name: "环球网",value: [18,82]},
          {name: "中国小康网",value: [23.2,76.8]},
          {name: "海外网",value: [10.9,89.1]},
          {name: "荆楚网",value: [5.5,94.5]},
          {name: "扬子晚报",value: [1.9,98.1]},
          {name: "上游新闻",value: [83,17]},
          {name: "中青网",value: [3.8,96.2]},
          {name: "澎湃新闻",value: [21.2,78.8]},
          {name: "深港在线",value: [49,51]},
          {name: "新闻",value: [38,62]},
          {name: "公务员考试网",value: [34.7,65.3]},
          {name: "中国网",value: [9.3,90.7]},
          {name: "祝甥供卵网",value: [14.6,85.4]},
          {name: "上海观察",value: [10.8,89.2]},
          {name: "台海网",value: [16.2,83.8]},
          {name: "城市网",value: [18.9,81.1]},
          {name: "千龙网",value: [2.8,97.2]},
          {name: "热门新闻",value: [16.7,83.3]},
          {name: "解放日报",value: [8.8,91.2]},
          {name: "央视网",value: [50,50]},
          {name: "杭州网",value: [18.8,81.2]},
          {name: "ZAKER",value: [25.8,74.2]},
          {name: "今日新闻",value: [43.3,56.7]},
          {name: "中国新闻网",value: [17.2,82.8]},
          {name: "中国评论新闻",value: [7.1,92.9]},
          {name: "中工网",value: [25.9,74.1]},
          {name: "机器网",value: [7.4,92.6]},
          {name: "盐城新闻网",value: [22.2,77.8]},
          {name: "今日关注",value: [96.2,3.8]},
          {name: "手机新浪网",value: [44,56]},
          {name: "新京报网",value: [4.2,95.8]},
          {name: "红歌会网",value: [4.3,95.7]},
          {name: "中财网",value: [4.5,95.5]},
          {name: "圣弗罗知识网",value: [38.1,61.9]},
          {name: "大众网",value: [15,85]},
          {name: "好朋友新闻网",value: [10,90]},
          {name: "乌有之乡网刊",value: [26.3,73.7]}]]
        },
        em_tre: {
          keys: ["2021-07-07","2021-07-08","2021-07-09","2021-07-10","2021-07-11","2021-07-12","2021-07-13","2021-07-14","2021-07-15","2021-07-16","2021-07-17","2021-07-18","2021-07-19","2021-07-20","2021-07-21","2021-07-22","2021-07-23","2021-07-24","2021-07-25","2021-07-26","2021-07-27","2021-07-28","2021-07-29","2021-07-30","2021-07-31","2021-08-01"
          ],
          negative: [279,2631,3130,1793,1596,1836,1502,977,759,785,873,403,241,253,182,114,72,66,75,44,157,122,30,4,31,51],
          positive: [361,1382,1857,1745,590,806,854,426,264,446,581,199,108,76,67,39,73,83,46,36,165,202,13,12,8,14]
        },
        em_tre_for_web_media: {
          keys: ["2021-07-07","2021-07-08","2021-07-09","2021-07-10","2021-07-11","2021-07-12","2021-07-13","2021-07-14","2021-07-15","2021-07-16","2021-07-17","2021-07-18","2021-07-19","2021-07-20","2021-07-21","2021-07-22","2021-07-23","2021-07-24","2021-07-25","2021-07-26","2021-07-27","2021-07-28","2021-07-29","2021-07-30","2021-07-31","2021-08-01"],
          negative: [76,610,833,491,494,587,472,336,257,247,302,134,80,87,71,39,28,35,17,15,44,41,12,2,9,15],
          positive: [41,49,259,189,96,150,200,59,50,87,82,51,30,29,34,17,12,8,25,22,15,20,3,4,4,10]
        },
        em_tre_for_weibo: {
          keys: ["2021-07-07","2021-07-08","2021-07-09","2021-07-10","2021-07-11","2021-07-12","2021-07-13","2021-07-14","2021-07-15","2021-07-16","2021-07-17","2021-07-18","2021-07-19","2021-07-20","2021-07-21","2021-07-22","2021-07-23","2021-07-24","2021-07-25","2021-07-26","2021-07-27","2021-07-28","2021-07-29","2021-07-30","2021-07-31","2021-08-01"],
          negative: [95,396,572,305,123,146,121,48,29,56,62,14,6,11,14,7,13,11,27,6,6,10,7,0,1,13],
          positive: [271,1021,1268,900,307,438,449,231,161,260,417,80,48,26,18,6,27,62,12,13,136,174,10,4,4,2]
        },
        em_tre_for_weixin: {
          keys: ['2019-07-01','2019-07-02','2019-07-03','2019-07-04','2019-07-05','2019-07-06','2019-07-07','2019-07-08','2019-07-09'],
          negative: [0, 0, 697, 2603, 1763, 1144, 801, 923, 675],
          positive: [0, 0, 234, 624, 364, 183, 189, 246, 174]
        },
        em_tre_for_forum: {
          keys: ['2019-07-01','2019-07-02','2019-07-03','2019-07-04','2019-07-05','2019-07-06','2019-07-07','2019-07-08','2019-07-09'],
          negative: [0, 0, 198, 237, 134, 38, 82, 75, 100],
          positive: [0, 0, 6, 33, 14, 6, 32, 32, 7]
        },
        em_tre_for_others: {
          keys: [
            "2021-07-07","2021-07-08","2021-07-09","2021-07-10","2021-07-11","2021-07-12","2021-07-13","2021-07-14","2021-07-15","2021-07-16","2021-07-17","2021-07-18","2021-07-19","2021-07-20","2021-07-21","2021-07-22","2021-07-23","2021-07-24","2021-07-25","2021-07-26","2021-07-27","2021-07-28","2021-07-29","2021-07-30","2021-07-31","2021-08-01"
          ],
          negative: [278,2618,3113,1779,1576,1812,1486,973,756,777,869,396,238,252,180,114,71,64,75,42,156,121,30,4,31,51],
          positive: [360,1379,1855,1742,588,799,853,425,263,445,580,195,108,74,67,38,72,83,46,35,163,202,13,12,8,14]
        },
        pos_vs_neg: {
          isIncubation: true,
          timeline: [
            {
              date: '2019-07-03',
              points: ['高峰点'],
              type: 'point',
              weight_neg: 1,
              weight_pos: 0,
              article_neg: {
                emotion: 10,
                medias: ['微博', '新浪', '腾讯'],
                total: 1526,
                rate: 44.94,
                title:
                  '【上海公安：新城控股董事长王某涉嫌猥亵女童案属实】#新城控股董事长猥亵女童案# 7月3日下午，上海公安方面回复@界面新闻 称，上市公司新城控股董事长王某涉嫌猥亵女童属实。据《新民晚报》报道，犯罪行为发生于6月29日下午，地点为万航渡路一家五星级酒店。被猥亵女童事后向在江苏的母亲打电话哭诉',
                source_url: 'https://weibo.com/1638782947/HBL6aiakY?type=comment#_rnd1565256494577'
              },
              article_pos: {
                emotion: 98,
                medias: ['微博', '凤凰网'],
                total: 926,
                rate: 43.15,
                title:
                  '美国、哥伦比亚分别对海地总统遇刺身亡表态​​​',
                source_url: 'https://finance.sina.com.cn/tech/2021-07-07/doc-ikqcfnca5539021.shtml'
              }
            },
            {
              state: '蔓延期',
              weight_neg: 1,
              weight_pos: 0,
              type: 'interval',
              articles_neg: [
                {
                  media: '微博',
                  source_url:
                    'https://weibo.com/1638782947/HBL6aiakY?type=comment#_rnd1565256494577',
                  title:
                    '【上海公安：新城控股董事长王某涉嫌猥亵女童案属实】#新城控股董事长猥亵女童案# 7月3日下午，上海公安方面回复@界面新闻 称，上市公司新城控股董事长王某涉嫌猥亵女童属实。据《新民晚报》报道，犯罪行为发生于6月29日下午，地点为万航渡路一家五星级酒店。被猥亵女童事后向在江苏的母亲打电话哭诉'
                },
                {
                  media: 'ZAKER',
                  source_url: 'http://www.sohu.com/a/324556860_157944',
                  title: '港股新城发展控股跳水，董事长猥亵女童被捕'
                },
                {
                  media: '微博',
                  source_url: 'https://weibo.com/1989660417/HBOwSCND7?type=comment',
                  title:
                    '新城控股董事长王振华猥亵9岁女童令人发指。全世界都有这种变态的怪人，而且不少都是有成就的，甚至还有宗教界的。对这种人没什么说的，只能用法律伺候。老胡注意到，这件事是女童母亲报的案，警方迅速对嫌疑人采取了法律措施，等待嫌疑人的肯定是刑罚。在这当中，女童的母亲迅速报案是一种勇敢，值得 ​​​'
                }
              ],
              articles_pos: [
                {
                  media: '微博',
                  source_url: 'https://weibo.com/1806128454/HBNWkft9o?type=comment',
                  title:
                    '还是这句：在你身后，陪你say no  ​​​#新城控股董事长猥亵女童案#@凤凰网视频：在你身后 陪你say no ​​​'
                },
                {
                  media: '微博',
                  source_url:
                    'https://weibo.com/5890672121/HBN0WwrRw?type=comment#_rnd1565239611836',
                  title:
                    '【评论|董事长猥亵女童案：消除“权贵受保护”的疑虑 面对兽行没有“恶性舆情”之说】今日，上市公司新城控股董事长王某被曝涉嫌猥亵9岁女童并致其轻伤，此消息一发布便迅速点燃网络普遍的怒火，与此同时一些指令截图也在网上流传，将此事件视为“恶性舆情”。王某身份特殊，执掌着资产数千亿的上市公​'
                },
                {
                  media: '微博',
                  source_url:
                    'https://weibo.com/1618051664/HBSLJ3MfH?type=comment#_rnd1565239668597',
                  title: '#新城控股董事长猥亵女童案# 这就是态度——#孩子是我们的底线# @微博热点 ​​'
                }
              ]
            },
            {
              date: '2019-07-04',
              points: ['衰退点'],
              type: 'point',
              weight_neg: 1,
              weight_pos: 0,
              article_neg: {
                emotion: 10,
                medias: ['微博', '搜狐', '搜狗'],
                total: 791,
                rate: 32.79,
                title:
                  '新城控股董事长王振华猥亵9岁女童令人发指。全世界都有这种变态的怪人，而且不少都是有成就的，甚至还有宗教界的。对这种人没什么说的，只能用法律伺候。老胡注意到，这件事是女童母亲报的案，警方迅速对嫌疑人采取了法律措施，等待嫌疑人的肯定是刑罚。在这当中，女童的母亲迅速报案是一种勇敢，值得 ​​​',
                source_url: 'https://weibo.com/1989660417/HBOwSCND7?type=comment'
              },
              article_pos: {
                emotion: 98,
                medias: ['微博'],
                total: 682,
                rate: 30.78,
                title: '#新城控股董事长猥亵女童案# 这就是态度——#孩子是我们的底线# @微博热点 ​​​',
                source_url: 'https://weibo.com/1618051664/HBSLJ3MfH?type=comment#_rnd1565239668597'
              }
            },
            {
              date: '2019-07-05',
              points: ['分界点', '爆发点'],
              type: 'point',
              weight_neg: 1,
              weight_pos: 0,
              article_neg: {
                emotion: 10,
                medias: ['微博', '凤凰网', '人民日报'],
                total: 674,
                rate: 34.01,
                title:
                  '#监狱里的歧视链#【白岩松评猥亵女童案：在“号里”也是在歧视链最底端】3日，涉嫌猥亵女童的新城控股董事长王某某被警方刑拘。4日，白岩松在节目中谈到，监狱中也会有“歧视链”，而这歧视链的最底层便是这些强奸、猥亵幼女的罪犯。他们在监狱里也是人人喊打，只能住在马桶边上！',
                source_url: 'https://weibo.com/2803301701/HC6wpwGaK?type=comment'
              },
              article_pos: {
                emotion: 98,
                medias: ['微博'],
                total: 601,
                rate: 31.52,
                title:
                  '#孩子是我们的底线#【#白岩松评猥亵女童案#：在“号里”也是在歧视链最底端】3日，涉嫌猥亵女童的新城控股董事长王某某被刑拘。4日，白岩松在节目中谈到，监狱中也会有“歧视链”，而这歧视链的最底层便是这些强奸、猥亵幼女的罪犯。他们在监狱里也是人人喊打，只能住在马桶边上！http://t.cn/AiOLaimk ​',
                source_url: 'https://weibo.com/1618051664/HC6RehjmD?type=comment'
              }
            },
            {
              date: '2019-07-06',
              points: ['高峰点', '衰退点'],
              type: 'point',
              weight_neg: 1,
              weight_pos: 0,
              article_neg: {
                emotion: 10,
                medias: ['微博', '人民日报', '搜狐'],
                total: 674,
                rate: 34.01,
                title:
                  '【你愿为他们转发吗？#面对儿童性侵沉默就是纵容#】近日，新城控股董事长涉嫌猥亵女童被刑拘，引起广泛声讨。儿童遭受猥亵、性侵绝非个例。仅2018年，媒体曝光的性侵儿童案例就有317起，受害儿童逾750人。相比未曝光的，这或许只是冰山一角。保护儿童，对性侵零容忍，他和她的平安，需要你我共同关注！',
                source_url: 'https://weibo.com/2803301701/HCaegwn4I?type=comment#_rnd1565257045230'
              },
              article_pos: {
                emotion: 98,
                medias: ['微博', '光明网'],
                total: 601,
                rate: 31.52,
                title:
                  '【家长注意！这五件事，要让孩子知道！】①隐私部位只有特定的人，在特定的情境下才能看；②不让别人碰是自己的权利，敢于对他人的奇怪要求说“不”； ③避免独自和陌生人在封闭环境中接触；④不随便接受陌生人给的食品；⑤如果有人告诉你，这件事不要告诉父母，那么一定要告诉父母！\n' +
                  '@人民日报：【你愿为他们转发吗？#面对儿童性侵沉默就是纵容#】近日，新城控股董事长涉嫌猥亵女童被刑拘，引起广泛声讨。儿童遭受猥亵、性侵绝非个例。仅2018年，媒体曝光的性侵儿童案例就有317起，受害儿童逾750人。相比未曝光的，这或许只是冰山一角。保护儿童，对性侵零容忍，他和她的平安，需要你我共同关注！​',
                source_url: 'https://weibo.com/2803301701/HCdJ6wNpx?type=comment'
              }
            },
            {
              state: '衰退期',
              weight_neg: 1,
              weight_pos: 0,
              type: 'interval',
              articles_neg: [
                {
                  media: '微博',
                  source_url:
                    'https://weibo.com/2803301701/HCaegwn4I?type=comment#_rnd1565257045230',
                  title:
                    '【你愿为他们转发吗？#面对儿童性侵沉默就是纵容#】近日，新城控股董事长涉嫌猥亵女童被刑拘，引起广泛声讨。儿童遭受猥亵、性侵绝非个例。仅2018年，媒体曝光的性侵儿童案例就有317起，受害儿童逾750人。相比未曝光的，这或许只是冰山一角。保护儿童，对性侵零容忍，他和她的平安，需要你我共同关注！'
                },
                {
                  media: '微博',
                  source_url: 'https://weibo.com/1644114654/HCxLn7NOH?type=comment',
                  title:
                    '【涉嫌猥亵女童 王振华被撤销上海市政协委员资格】据上观新闻，今日，上海市政协召开十三届二十九次主席会议。按照市政协委员资格条件和有关要求，会议决定，撤销王振华第十三届上海市政协委员资格。@新京报：#新京报WEEKLY#【#性侵儿童案年均300起以上# 拿什么来保护我们的孩子？】据《新民晚报》报道，6月29日下午上海万航渡路一家五星级酒店发生一起涉嫌猥亵儿童案，引起舆论广泛关注。上海警方7月3日通报称，犯罪嫌疑人王某某、周某某因涉嫌猥亵儿童罪已被警方刑事拘留。新城控股3日晚间公告，公司董事长王 ​'
                },
                {
                  media: '微博',
                  source_url:
                    'https://weibo.com/1784473157/HCD101nBt?type=comment#_rnd1565257175985',
                  title:
                    '【#王振华辞去新城控股所有职务#】新城控股8日晚发布公告称，7月8日收到董事王振华书面辞职报告。王振华因个人原因申请辞去公司第二届董事会董事、提名委员会委员、战略委员会主任委员的职务。王振华辞职后将不再担任公司任何职务。日前，#王振华涉嫌猥亵女童被警方刑事拘留#，其子王晓松已接任新城控 ​​​​'
                }
              ],
              articles_pos: [
                {
                  media: '微博',
                  source_url: 'https://weibo.com/2803301701/HCdJ6wNpx?type=comment',
                  title:
                    '【家长注意！这五件事，要让孩子知道！】①隐私部位只有特定的人，在特定的情境下才能看；②不让别人碰是自己的权利，敢于对他人的奇怪要求说“不”； ③避免独自和陌生人在封闭环境中接触；④不随便接受陌生人给的食品；⑤如果有人告诉你，这件事不要告诉父母，那么一定要告诉父母！@人民日报：【你愿为他们转发吗？#面对儿童性侵沉默就是纵容#】近日，新城控股董事长涉嫌猥亵女童被刑拘，引起广泛声讨。儿童遭受猥亵、性侵绝非个例。仅2018年，媒体曝光的性侵儿童案例就有317起，受害儿童逾750人。相比未曝光的，这或许只是冰山一角。保护儿童，对性侵零容忍，他和她的平安，需要你我共同关注！​​'
                },
                {
                  media: '微博',
                  source_url: 'https://weibo.com/1686546714/HCphHwpZP?type=comment',
                  title:
                    '【白岩松评上市公司老总性侵幼女案：犯罪者多数不是死刑】新城控股创始人王振华3日被拘后，白岩松4日在《新闻1+1》中一评猥亵女童案。5日，新城控股发布公开信致歉。次日，白岩松在《新闻周刊》再次对此事发表评论。白岩松说，今年全国两会期间对性侵未成年人案件有过一份调查报告，报告称此类案件熟人 ​​'
                },
                {
                  media: '微博',
                  source_url: 'https://weibo.com/6124642021/HCyqDcIkV?type=comment',
                  title:
                    '【#新城控股品牌公关回应#：市场最终会反映公司价值】7月8日，上海市政协决定撤销王振华上海市政协委员资格。同日，新城控股连续第三个交易日跌停，因王振华涉嫌猥亵事件，市值缩水超260亿元。新城控股上海总部品牌公关称，公司在新董事长的带领下，公司团队稳定，经营活动正常开展，希望大家理性看待 ​​'
                }
              ]
            },
            {
              date: '2019-07-09',
              points: ['结束点'],
              type: 'point',
              weight_neg: 1,
              weight_pos: 0,
              article_neg: {
                emotion: 10,
                medias: ['微博', '中国新闻', '虎嗅'],
                total: 299,
                rate: 34.81,
                title:
                  '【#王振华辞去新城控股所有职务#】新城控股8日晚发布公告称，7月8日收到董事王振华书面辞职报告。王振华因个人原因申请辞去公司第二届董事会董事、提名委员会委员、战略委员会主任委员的职务。王振华辞职后将不再担任公司任何职务。日前，#王振华涉嫌猥亵女童被警方刑事拘留#，其子王晓松已接任新城控 ​​​',
                source_url: 'https://weibo.com/1784473157/HCD101nBt?type=comment#_rnd1565257175985'
              },
              article_pos: {
                emotion: 98,
                medias: ['微博', '中新网', '一点资讯'],
                total: 254,
                rate: 32.05,
                title:
                  '【新城控股还原王振华刑拘后24小时：其子当晚获悉 不存在违规交易】#新城控股称不存在内幕交易#7月8日晚，新城控股（601155.SH）一则对上海证券交易所问询的回复公告，还原了公司实际控制人王振华猥亵女童案发后新城控股经历的24小时。7月2日，新城控股现任董事长、总裁王晓松接到上海市长风新村派出',
                source_url: 'https://weibo.com/1618051664/HCCv8lBmt?type=comment#_rnd1565250800281'
              }
            }
          ],
          trend: [
            {
              count: 0,
              date: '2019-07-01',
              points: ['开始点', '最低点']
            },
            {
              count: 0,
              date: '2019-07-02',
              points: []
            },
            {
              count: 80244,
              date: '2019-07-03',
              points: ['高峰点']
            },
            {
              count: 67253,
              date: '2019-07-04',
              points: ['衰退点']
            },
            {
              count: 20644,
              date: '2019-07-05',
              points: ['分界点']
            },
            {
              count: 58690,
              date: '2019-07-06',
              points: ['高峰点', '衰退点']
            },
            {
              count: 16552,
              date: '2019-07-07'
            },
            {
              count: 7472,
              date: '2019-07-08'
            },
            {
              count: 4941,
              date: '2019-07-09',
              points: ['结束点']
            }
          ]
        },
        neg_dis_for_cat: {
          type: ['微博', '网媒', '论坛', '政务', '博客', '报刊'],
          data: [171852, 10207, 8627, 873, 26, 9, ]
        },
        neg_dis_for_web_media: {
          type: ['一般媒体', '官媒', '国外媒体'],
          data: [191579, 204, 16]
        },
        neg_dis_for_weibo_type: {
          type: ['转发', '原发'],
          data: [164011, 7841]
        },
        neg_dis_for_weibo_user: {
          type: ['普通用户', '达人', '黄V', '蓝V', '金V', '微博女郎'],
          data: [142429, 15498, 9434, 2722, 1574, 195]
        },
        neg_num_top: [
          {
            name: '腾讯网',
            total: 2863
          },
          {
            name: '今日头条',
            total: 2243
          },
          {
            name: '微博',
            total: 2163
          },
          {
            name: '搜狐',
            total: 1313
          },
          {
            name: '新浪网',
            total: 1293
          },
          {
            name: '趣头条',
            total: 1203
          },
          {
            name: '网易',
            total: 523
          },
          {
            name: '搜狐资讯',
            total: 369
          },
          {
            name: '手机江西台',
            total: 350
          },
          {
            name: '百度',
            total: 325
          }
        ],
        neg_por_top: [
          {
            name: '大鱼号',
            percent: 91.4
          },
          {
            name: '人民网',
            percent:91.3
          },
          {
            name: 'zaker新闻',
            percent: 89.7
          },
          {
            name: '东方网',
            percent: 88.7
          },
          {
            name: '中国网',
            percent: 88.5
          },
          {
            name: '趣头条',
            percent: 88.4
          },
          {
            name: '南京澎湃文化传媒有限公司',
            percent: 88.1
          },
          {
            name: '凤凰资讯',
            percent: 87.8
          },
          {
            name: '中评网',
            percent: 86.3
          },
          {
            name: '搜狐资讯',
            percent: 86.1
          }
        ],
        neg_tre_date: {
          range: ["2021-07-07","2021-08-01"],
          data: [
           ["2021-07-07",293],["2021-07-08",2670],["2021-07-09",3184],["2021-07-10",2140],["2021-07-11",1611],["2021-07-12",1866],["2021-07-13",1522],["2021-07-14",984],["2021-07-15",773],["2021-07-16",803],["2021-07-17",889],["2021-07-18",409],["2021-07-19",251],["2021-07-20",255],["2021-07-21",184],["2021-07-22",115],["2021-07-23",73],["2021-07-24",66],["2021-07-25",75],["2021-07-26",44],["2021-07-27",158],["2021-07-28",123],["2021-07-29",30],["2021-07-30",4],["2021-07-31",31],["2021-08-01",51]          ]
        },
        neg_tre: {
          keys: ["2021-07-07","2021-07-08","2021-07-09","2021-07-10","2021-07-11","2021-07-12","2021-07-13","2021-07-14","2021-07-15","2021-07-16","2021-07-17","2021-07-18","2021-07-19","2021-07-20","2021-07-21","2021-07-22","2021-07-23","2021-07-24","2021-07-25","2021-07-26","2021-07-27","2021-07-28","2021-07-29","2021-07-30","2021-07-31","2021-08-01"],
          type: ["自媒体号","网媒","客户端","微博","评论","报刊","论坛","视频","Telegram","政务","推特","博客","微信"],
         data: [
              {
                  "name": "自媒体号",
                  "data": [72,1217,1233,693,644,706,599,404,329,378,350,162,104,118,62,48,24,9,18,17,87,54,8,0,14,13            ]
              },
              {
                  "name": "网媒",
                  "data": [76,614,840,505,495,592,479,338,263,250,306,137,82,89,71,39,29,35,17,15,44,41,12,2,9,15            ]
              },
              {
                  "name": "客户端",
                  "data": [25,373,357,532,242,287,261,154,136,88,152,62,48,32,31,17,6,9,13,4,17,8,2,2,7,3            ]
              },
              {
                  "name": "微博",
                  "data": [104,404,595,311,128,152,123,48,29,56,64,14,7,11,14,7,13,11,27,6,7,11,7,0,1,13            ]
              },
              {
                  "name": "评论",
                  "data": [14,33,65,63,39,26,10,9,6,15,0,5,2,2,4,0,0,0,0,0,0,8,0,0,0,0            ]
              },
              {
                  "name": "报刊",
                  "data": [1,2,48,18,36,55,31,26,1,5,11,22,2,0,0,1,0,0,0,0,1,0,1,0,0,7            ]
              },
              {
                  "name": "论坛",
                  "data": [1,13,17,14,21,25,16,4,3,8,4,7,3,1,2,0,1,2,0,2,1,1,0,0,0,0            ]
              },
              {
                  "name": "视频",
                  "data": [0,10,19,2,5,13,2,0,2,2,1,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0            ]
              },
              {
                  "name": "Telegram",
                  "data": [0,1,5,1,0,5,1,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0            ]
              },
              {
                  "name": "政务",
                  "data": [0,1,0,0,0,3,0,0,1,0,1,0,1,0,0,2,0,0,0,0,1,0,0,0,0,0            ]
              },
              {
                  "name": "推特",
                  "data": [0,2,4,1,0,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0            ]
              },
              {
                  "name": "博客",
                  "data": [0,0,1,0,1,0,0,0,0,0,0,0,0,1,0,1,0,0,0,0,0,0,0,0,0,0            ]
              },
              {
                  "name": "微信",
                  "data": [0,0,0,0,0,0,0,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0            ]
              }
          ]
        },
        neg_tre_for_web_media: {
          keys: ["2021-07-07 00:00:00","2021-07-08 00:00:00","2021-07-09 00:00:00","2021-07-10 00:00:00","2021-07-11 00:00:00","2021-07-12 00:00:00","2021-07-13 00:00:00","2021-07-14 00:00:00","2021-07-15 00:00:00","2021-07-16 00:00:00","2021-07-17 00:00:00","2021-07-18 00:00:00","2021-07-19 00:00:00","2021-07-20 00:00:00","2021-07-21 00:00:00","2021-07-22 00:00:00","2021-07-23 00:00:00","2021-07-24 00:00:00","2021-07-25 00:00:00","2021-07-26 00:00:00","2021-07-27 00:00:00","2021-07-28 00:00:00","2021-07-29 00:00:00","2021-07-30 00:00:00","2021-07-31 00:00:00","2021-08-01 00:00:00"          ],
          type: ['一般媒体', '官媒', '国外媒体'],
          data: [
            {
              name: '一般媒体',
              data: [284,2616,3082,2116,1566,1838,1513,962,754,800,867,405,247,251,172,115,73,62,74,44,158,116,30,4,31,50]
            },
            {
              name: '官媒',
              data: [9,54,102,24,45,28,9,22,19,3,22,4,4,4,12,0,0,4,1,0,0,7,0,0,0,]
            },
            {
              name: '国外媒体',
              data: [0,5,4,5,2,3,1,0,1,0,2,0,1,0,0,2,0,0,0,0,1,0,0,0,0,0]
            }
          ]
        },
        neg_tre_for_weibo_type: {
          keys: ["2021-07-07 00:00:00","2021-07-08 00:00:00","2021-07-09 00:00:00","2021-07-10 00:00:00","2021-07-11 00:00:00","2021-07-12 00:00:00","2021-07-13 00:00:00","2021-07-14 00:00:00","2021-07-15 00:00:00","2021-07-16 00:00:00","2021-07-17 00:00:00","2021-07-18 00:00:00","2021-07-19 00:00:00","2021-07-20 00:00:00","2021-07-21 00:00:00","2021-07-22 00:00:00","2021-07-23 00:00:00","2021-07-24 00:00:00","2021-07-25 00:00:00","2021-07-26 00:00:00","2021-07-27 00:00:00","2021-07-28 00:00:00","2021-07-29 00:00:00","2021-07-30 00:00:00","2021-07-31 00:00:00","2021-08-01 00:00:00"
          ],
          forward: [82,290,429,222,94,86,86,31,17,38,30,5,1,7,5,3,11,10,22,2,6,7,5,0,0,8],
          original: [22,114,166,89,34,66,37,17,12,18,34,9,6,4,9,4,2,1,5,4,1,4,2,0,1,5]
        },
        neg_tre_for_weibo_user: {
          keys: ["2021-07-07","2021-07-08","2021-07-09","2021-07-10","2021-07-11","2021-07-12","2021-07-13","2021-07-14","2021-07-15","2021-07-16","2021-07-17","2021-07-18","2021-07-19","2021-07-20","2021-07-21","2021-07-22","2021-07-23","2021-07-24","2021-07-25","2021-07-26","2021-07-27","2021-07-28","2021-07-29","2021-07-30","2021-07-31","2021-08-01"
          ],
          type: ["普通用户","黄V","蓝V","金V","达人"],
          data: [
            {
              name: '普通用户',
              data: [76,271,383,211,96,101,80,24,22,40,43,8,4,9,11,4,11,9,21,5,7,7,6,0,0,6]
            },
            {
              name: '达人',
              data: [12,46,72,40,15,23,17,8,3,4,6,1,2,0,1,1,1,0,2,1,0,3,0,0,0,1]
            },
            {
              name: '黄V',
              data: [4,31,62,15,2,8,14,8,1,3,9,0,1,0,1,2,0,1,4,0,0,1,0,0,0,3]
            },
            {
              name: '蓝V',
              data: [7,36,45,28,8,11,7,5,2,8,6,2,0,0,1,0,0,1,0,0,0,0,1,0,1,1]
            },
            {
              name: '金V',
              data: [5,20,33,17,7,9,5,3,1,1,0,3,0,2,0,0,1,0,0,0,0,0,0,0,0,2]
            }
          ]
        },
        neg_op: [
          {"view": "哥伦比亚政府公布17名参与刺杀海地总统嫌犯身份","source_url": "http://u1egusc2.no-yes.cn/data/4700182.shtml","total": 465,"rate": 2.5},
          {"view": "哥伦比亚警方：海地前公职人员下令刺杀海地总统","source_url": "https://m.sohu.com/cm/478429512_612784?id=210528963","total": 279,"rate": 1.5},
          {"view": "至少有28名嫌疑人参与刺杀海地总统","source_url": "http://news.taizhou.com.cn/2021-07/11/content_6473516.htm","total": 142,"rate": 0.8},
          {"view": "哥伦比亚政府对涉嫌参与刺杀海地总统的3名公民展开调查","source_url": "http://world.qianlong.com/2021/0716/6035603.shtml","total": 135,"rate": 0.7},
          {"view": "美国、哥伦比亚将派人协助调查海地总统遇刺案","source_url": "http://www.syamj.cn/show/7653902.shtml","total": 115,"rate": 0.6},
          {"view": "美国承认了！“杀手是我培训的”","source_url": "https://junshi.china.com/redian/38468511.html","total": 99,"rate": 0.5},
          {"view": "海地前官员下令刺杀总统？总统之妻或归国出席葬礼","source_url": "http://www.tibet.cn/cn/Instant/expo/202107/t20210719_7032509.html","total": 94,"rate": 0.5},
          {"view": "“主谋”之一落网？海地总统遇刺案情曲折","source_url": "https://m.toutiao.com/a6984019570340135438","total": 90,"rate": 0.5},
          {"view": "海地总理爆料：总统被刺杀前曾遭酷刑","source_url": "http://www.yidianzixun.com/article/0VkhcpNc","total": 88,"rate": 0.5},
          {"view": "外媒：海地请求美国派兵稳定局势","source_url": "https://www.jinriguanzhu.cc/wap/jxIojj0097.html","total": 87,"rate": 0.5}],
        neg_key: [
          {
          "keyword": "刺杀",
          "total": 9397
          },
          {
          "keyword": "伊兹",
          "total": 8823
          },
          {
          "keyword": "遇刺",
          "total": 7344
          },
          {
          "keyword": "海地总统",
          "total": 7022
          },
          {
          "keyword": "约瑟夫",
          "total": 5090
          },
          {
          "keyword": "哥伦比亚",
          "total": 4016
          },
          {
          "keyword": "总统",
          "total": 3808
          },
          {
          "keyword": "嫌犯",
          "total": 3224
          },
          {
          "keyword": "暗杀",
          "total": 2502
          },
          {
          "keyword": "嫌疑人",
          "total": 1911
          },
          {
          "keyword": "雇佣兵",
          "total": 1909
          },
          {
          "keyword": "身亡",
          "total": 1517
          },
          {
          "keyword": "被捕",
          "total": 1487
          },
          {
          "keyword": "武装",
          "total": 1327
          },
          {
          "keyword": "警方",
          "total": 1254
          },
          {
          "keyword": "美国",
          "total": 1244
          },
          {
          "keyword": "蒂娜",
          "total": 1170
          },
          {
          "keyword": "美国人",
          "total": 1095
          },
          {
          "keyword": "7日",
          "total": 1034
          },
          {
          "keyword": "7月8日",
          "total": 1030
          },
          {
          "keyword": "总理",
          "total": 1020
          },
          {
          "keyword": "海外网",
          "total": 996
          },
          {
          "keyword": "索拉",
          "total": 949
          },
          {
          "keyword": "参议院",
          "total": 929
          },
          {
          "keyword": "9日",
          "total": 899
          },
          {
          "keyword": "第一夫人",
          "total": 895
          },
          {
          "keyword": "委内瑞拉",
          "total": 885
          },
          {
          "keyword": "逮捕",
          "total": 881
          },
          {
          "keyword": "哥伦比亚人",
          "total": 855
          },
          {
          "keyword": "反对派",
          "total": 834
          },
          {
          "keyword": "总统夫人",
          "total": 808
          },
          {
          "keyword": "抓捕",
          "total": 771
          },
          {
          "keyword": "拜登",
          "total": 752
          },
          {
          "keyword": "7月7日",
          "total": 729
          },
          {
          "keyword": "任期",
          "total": 716
          },
          {
          "keyword": "公职人员",
          "total": 647
          },
          {
          "keyword": "莱昂",
          "total": 633
          },
          {
          "keyword": "CTU",
          "total": 619
          },
          {
          "keyword": "下令",
          "total": 604
          },
          {
          "keyword": "8日",
          "total": 556
          },
          {
          "keyword": "袭击",
          "total": 526
          },
          {
          "keyword": "住所",
          "total": 524
          },
          {
          "keyword": "杜克",
          "total": 509
          },
          {
          "keyword": "哥伦比亚政府",
          "total": 503
          },
          {
          "keyword": "团伙",
          "total": 496
          },
          {
          "keyword": "政局",
          "total": 488
          },
          {
          "keyword": "10日",
          "total": 454
          },
          {
          "keyword": "任命",
          "total": 450
          },
          {
          "keyword": "参与",
          "total": 433
          },
          {
          "keyword": "迈阿密",
          "total": 433
          }
        ],
        neg_op_tre: {
          keys: [
            "2021-07-07 00:00:00",
            "2021-07-08 00:00:00",
            "2021-07-09 00:00:00",
            "2021-07-10 00:00:00",
            "2021-07-11 00:00:00",
            "2021-07-12 00:00:00",
            "2021-07-13 00:00:00",
            "2021-07-14 00:00:00",
            "2021-07-15 00:00:00",
            "2021-07-16 00:00:00",
            "2021-07-17 00:00:00",
            "2021-07-18 00:00:00",
            "2021-07-19 00:00:00",
            "2021-07-20 00:00:00",
            "2021-07-21 00:00:00",
            "2021-07-22 00:00:00",
            "2021-07-23 00:00:00",
            "2021-07-25 00:00:00",
            "2021-07-27 00:00:00",
            "2021-07-28 00:00:00"
          ],
          data: [
            [
             {
              "title": "美国、哥伦比亚分别对海地总统遇刺身亡表态",
              "source_url": "https://finance.sina.com.cn/tech/2021-07-07/doc-ikqcfnca5539021.shtml"
              },
              {
              "title": "//@张哲瀚的欢翎晗间://@乐乐熙间4://@乐乐熙间4:[蜡烛]//@勿忘星辰勿忘我:缅怀先烈 吾辈自强//@Limerence_张哲瀚数据站:#七七事变84周年# 勿忘历史，吾辈自强！铭记！//",
              "source_url": "https://weibo.com/3202113747/KnCEiv9zP"
              },
              {
              "title": "突发！外媒：海地总统莫伊兹遇刺中枪身亡，妻子也受伤入院",
              "source_url": "https://m.toutiao.com/a6982136622187889165"
              }
              ],
              [
              {
              "title": "海地总统和第一夫人遇刺身亡 外媒：该国或更不稳定",
              "source_url": "https://www.toutiao.com/a6982334134903374350"
              },
              {
              "title": "海地总统遇刺案初步调查结果公布：莫伊兹身中12枪",
              "source_url": "https://c.m.163.com/news/a/GECH918C0001899O.html"
              },
              {
              "title": "总统遇刺身亡加剧海地动荡",
              "source_url": "https://www.toutiao.com/a6982500834735194631"
              }
              ],
              [
              {
              "title": "至少有28名嫌疑人参与刺杀海地总统",
              "source_url": "https://www.360kuai.com/pc/9a3082e9849c0e72e?cota=3&kuai_so=1&refer_scene=so_3&sign=360_da20e874&tj_url=so_vip"
              },
              {
              "title": "海地总统疑被美国枪杀!曾遭美两度入侵,还被特朗...",
              "source_url": "http://www.baidu.com/link?url=2-3URmWvbo0T_D6RFZlYL0k0OGReP3fmdHd06ZrdcK97nXi4pwO6kmUVUGihbqgtBpCybMsf6uggV9ZKznANwa"
              },
              {
              "title": "海地警方确定28名刺杀总统的嫌疑犯身份",
              "source_url": "https://www.360kuai.com/9d28ed918be4dea46?sign=look"
              }
              ],
              [
              {
              "title": "哥伦比亚政府公布17名参与刺杀海地总统嫌犯身份",
              "source_url": "https://www.toutiao.com/a6983283106589442596"
              },
              {
              "title": "外媒：海地请求美国派兵稳定局势",
              "source_url": "https://c.m.163.com/news/a/GEH73IBR0514R9P4.html?spss=backflow-index-hotlist"
              },
              {
              "title": "哥伦比亚证实13名退役军人参与刺杀 将协助海地调查",
              "source_url": "https://www.360kuai.com/9ecb2b959ad14224d?sign=look"
              }
              ],
              [
              {
              "title": "美国、哥伦比亚将派人协助调查海地总统遇刺案",
              "source_url": "https://www.360kuai.com/99adda4198a75ef24?sign=look"
              },
              {
              "title": "海地参议院议长：临时总统就职仪式推迟",
              "source_url": "http://news.cnwest.com/tianxia/a/2021/07/11/19796273.html"
              },
              {
              "title": "海地总统为何遭暗杀？其遗孀首度发声曝幕后凶手动机",
              "source_url": "http://www.10yan.com/qcw/2021/07/11/724479.shtml"
              }
              ],
              [
              {
              "title": "外媒:海地总统刺杀事件中哥伦比亚嫌犯受雇于美国一公司",
              "source_url": "https://www.360kuai.com/pc/9cf1b8bda88c0930b?cota=3&kuai_so=1&refer_scene=so_3&sign=360_da20e874&tj_url=so_vip"
              },
              {
              "title": "海地警方：总统遇刺事件疑似主谋自称想当总统",
              "source_url": "http://app.myzaker.com/news/article.php?pk=60ec253c8e9f095a04703f6d"
              },
              {
              "title": "海地第一夫人发布音频：是总统的政敌“派来了雇佣兵”",
              "source_url": "http://news.hsw.cn/system/2021/0712/1343546.shtml"
              }
              ],
              [
              {
              "title": "美不排除向海地派军可能 外媒：海地政局陷“一团糟”",
              "source_url": "http://www.cwestc.com/newshtml/2021-7-13/680848.shtml"
              },
              {
              "title": "\"主谋\"之一落网?海地总统遇刺案情曲折",
              "source_url": "https://www.360kuai.com/99cadce60be172b2c?sign=look"
              },
              {
              "title": "外媒：海地总统遇刺案一被捕美国人曾是美缉毒局线人",
              "source_url": "https://www.toutiao.com/a6984249380404625933"
              }
              ],
              [
              {
              "title": "海地总统遇刺案：谜团愈演愈烈 派军请求未获回应",
              "source_url": "https://m.toutiao.com/a6984594201195315719"
              },
              {
              "title": "委内瑞拉高官：涉嫌刺杀海地总统的美安保公司曾参与暗杀马杜罗",
              "source_url": "https://feng.ifeng.com/c/87rsWMEYyrJ"
              },
              {
              "title": "海地总统遇刺案:美国毒品管制局前线人身份被确认",
              "source_url": "https://www.360kuai.com/96231f23ee9a9b775?sign=look"
              }
              ],
              [
              {
              "title": "暗杀马杜罗？起底海地总统遇刺案背后神秘的CTU安保公司",
              "source_url": "https://m.toutiao.com/a6984967342656586270"
              },
              {
              "title": "谁干的？海地总统刺杀案做调查的法官助理收到“严重死亡威胁”",
              "source_url": "http://dy.163.com/v2/article/detail/GEUOAV930514R9OJ.html"
              },
              {
              "title": "海地警方搜寻5名涉嫌刺杀总统逃犯 含知名政客及前政府官员",
              "source_url": "https://www.360kuai.com/946eb93052e4557b9?sign=look"
              }
              ],
              [
              {
              "title": "哥伦比亚政府对涉嫌参与刺杀海地总统的3名公民展开调查",
              "source_url": "https://c.m.163.com/news/a/GF0SA2D505346RC6.html"
              },
              {
              "title": "美国承认：杀手是我培训的",
              "source_url": "https://new.qq.com/omn/20210716/20210716A04L5I00.html"
              },
              {
              "title": "拜登表示尚无计划向海地派兵",
              "source_url": "https://www.sohu.com/a/477791151_267106#comment_area"
              }
              ],
              [
              {
              "title": "哥伦比亚警方：海地前公职人员下令刺杀海地总统",
              "source_url": "https://m1.tt.cn/mobile/210717065155993.html"
              },
              {
              "title": "海地前官员下令刺杀总统?总统之妻或归国出席葬礼",
              "source_url": "https://www.360kuai.com/917a85a9c28b45c4f?sign=look"
              },
              {
              "title": "哥伦比亚警方:刺杀海地总统命令来自海地前官员,此人仍下落不明",
              "source_url": "https://www.360kuai.com/pc/90ac84d4767889b94?cota=3&kuai_so=1&refer_scene=so_3&sign=360_da20e874&tj_url=so_vip"
              }
              ],
              [
              {
              "title": "台媒：两个“邦交国”恐生变，当地批民进党当局“支持独裁者”",
              "source_url": "http://www.taihainet.com/news/twnews/latq/2021-07-18/2532059.html"
              },
              {
              "title": "海地总统被刺真相曝光，曾强行解散亲美傀儡议会，与美国闹翻",
              "source_url": "https://www.toutiao.com/a6986061144108794399"
              },
              {
              "title": "海地总统遗孀从美国出院返回海地，将参加总统葬礼",
              "source_url": "https://m.toutiao.com/a6986090254511899149"
              }
              ],
              [
              {
              "title": "涉嫌刺杀海地总统前军官：接到任务要协助一名女性就任总统",
              "source_url": "https://xw.qq.com/cmsid/20210719A04HA300"
              },
              {
              "title": "外媒：刺杀海地总统主谋或是一名女性，想出任总统",
              "source_url": "https://www.sohu.com/a/478395788_162758#comment_area"
              },
              {
              "title": "刺杀海地总统嫌犯:任务要求协助一名女性就任总统",
              "source_url": "https://www.360kuai.com/9b7db051c4fb05aca?sign=look"
              }
              ],
              [
              {
              "title": "外媒：海地临时总理约瑟夫同意卸任 由总统生前指定人选接任",
              "source_url": "http://cn.apdnews.com/toutiao/1012675.html"
              },
              {
              "title": "海地新政府将在无总统情况下开始工作",
              "source_url": "https://t.cj.sina.cn/articles/view/5182171545/134e1a999020013rha"
              },
              {
              "title": "外媒：海地临时总理约瑟夫同意卸任，由总统生前指定人选接任",
              "source_url": "https://news.dayoo.com/world/202107/20/139998_53994985.htm"
              }
              ],
              [
              {
              "title": "海地警方:3名警察涉总统刺杀案被捕,警察队伍早被渗透",
              "source_url": "https://www.360kuai.com/92a25635368f021e7?sign=look"
              },
              {
              "title": "海地警方:3名警察涉总统刺杀案被捕,警方内部早被渗透",
              "source_url": "https://www.360kuai.com/98d18a208d9dd2f39?sign=look"
              },
              {
              "title": "海地警方：3名警察涉总统刺杀案被捕",
              "source_url": "http://gb.crntt.com/doc/7_0_106140952_1_0721112031.html"
              }
              ],
              [
              {
              "title": "深度还原 | “快来救我”，海地总统遇刺前求救10分钟",
              "source_url": "http://html2.qttcs3.cn/detail/share/1634313/16343123/163431228/1634312279.html"
              },
              {
              "title": "同美国为邻，从不是件易事",
              "source_url": "http://art.lywka.cn/detail/share/1634303/16343021/163430210/1634302093.html"
              },
              {
              "title": "【法治热点早知道】男子醉倒路边，两女子热心帮打120送医却被刑拘！原因让人大跌眼镜",
              "source_url": "https://m.toutiao.com/a6987510204908716583"
              }
            ]
          ]
        },
        neg_key_tre: {
          keys: ['2019-07-03', '2019-07-04', '2019-07-05', '2019-07-06', '2019-07-09'],
          data: [
            [
              {
                keyword: '女童',
                total: 65985
              },
              {
                keyword: '新城',
                total: 64800
              },
              {
                keyword: '董事长',
                total: 62035
              },
              {
                keyword: '控股',
                total: 61664
              },
              {
                keyword: '王某',
                total: 34527
              },
              {
                keyword: '撕裂伤',
                total: 22099
              },
              {
                keyword: '新民晚报',
                total: 20752
              },
              {
                keyword: '王振华',
                total: 20605
              },
              {
                keyword: '强制措施',
                total: 19303
              },
              {
                keyword: '上海',
                total: 19329
              },
              {
                keyword: '周某',
                total: 15930
              },
              {
                keyword: '犯罪行为',
                total: 13263
              },
              {
                keyword: '母亲',
                total: 12106
              },
              {
                keyword: '上市公司',
                total: 10330
              },
              {
                keyword: '阴道',
                total: 8934
              },
              {
                keyword: '验伤',
                total: 6755
              },
              {
                keyword: '有期徒刑',
                total: 4597
              },
              {
                keyword: '女儿',
                total: 4158
              },
              {
                keyword: '酒店',
                total: 3709
              },
              {
                keyword: '幼女',
                total: 2783
              }
            ],
            [
              {
                keyword: '新城',
                total: 51453
              },
              {
                keyword: '女童',
                total: 49473
              },
              {
                keyword: '控股',
                total: 48609
              },
              {
                keyword: '董事长',
                total: 40299
              },
              {
                keyword: '王振华',
                total: 16957
              },
              {
                keyword: '撕裂伤',
                total: 10454
              },
              {
                keyword: '王某',
                total: 8324
              },
              {
                keyword: '上海',
                total: 6326
              },
              {
                keyword: '新民晚报',
                total: 5906
              },
              {
                keyword: '凤凰网',
                total: 5753
              },
              {
                keyword: '周某',
                total: 5242
              },
              {
                keyword: '阴道',
                total: 5120
              },
              {
                keyword: '法院',
                total: 4389
              },
              {
                keyword: '王某某',
                total: 4365
              },
              {
                keyword: '周某某',
                total: 4280
              },
              {
                keyword: '儿童',
                total: 3890
              },
              {
                keyword: '强制措施',
                total: 3961
              },
              {
                keyword: '公安',
                total: 3453
              },
              {
                keyword: '犯罪行为',
                total: 3139
              },
              {
                keyword: '幼童',
                total: 2122
              }
            ],
            [
              {
                keyword: '新城',
                total: 15588
              },
              {
                keyword: '控股',
                total: 13813
              },
              {
                keyword: '女童',
                total: 13529
              },
              {
                keyword: '董事长',
                total: 9781
              },
              {
                keyword: '王振华',
                total: 6236
              },
              {
                keyword: '儿童',
                total: 2750
              },
              {
                keyword: '王某某',
                total: 2646
              },
              {
                keyword: '幼女',
                total: 2009
              },
              {
                keyword: '底线',
                total: 1744
              },
              {
                keyword: '周某某',
                total: 1394
              },
              {
                keyword: '动画',
                total: 1206
              },
              {
                keyword: '王某',
                total: 1132
              },
              {
                keyword: '撕裂伤',
                total: 1126
              },
              {
                keyword: '态度',
                total: 1125
              },
              {
                keyword: '保护',
                total: 1026
              },
              {
                keyword: '监狱',
                total: 960
              },
              {
                keyword: '歧视',
                total: 935
              },
              {
                keyword: '人人喊打',
                total: 923
              },
              {
                keyword: '底端',
                total: 920
              },
              {
                keyword: '最底层',
                total: 900
              }
            ],
            [
              {
                keyword: '儿童',
                total: 50566
              },
              {
                keyword: '儿童性',
                total: 49961
              },
              {
                keyword: '个例',
                total: 49874
              },
              {
                keyword: '冰山一角',
                total: 49359
              },
              {
                keyword: '零容忍',
                total: 48507
              },
              {
                keyword: '新城',
                total: 41482
              },
              {
                keyword: '控股',
                total: 35412
              },
              {
                keyword: '女童',
                total: 34502
              },
              {
                keyword: '刑拘',
                total: 32725
              },
              {
                keyword: '小甜饼',
                total: 6559
              },
              {
                keyword: '镣铐',
                total: 4948
              },
              {
                keyword: '董事长',
                total: 3604
              },
              {
                keyword: '王某某',
                total: 2343
              },
              {
                keyword: '歧视',
                total: 1977
              },
              {
                keyword: '监狱',
                total: 1937
              },
              {
                keyword: '幼女',
                total: 1896
              },
              {
                keyword: '人人喊打',
                total: 1876
              },
              {
                keyword: '王振华',
                total: 1802
              },
              {
                keyword: '保护',
                total: 1782
              },
              {
                keyword: '父母',
                total: 1413
              }
            ],
            [
              {
                keyword: '新城',
                total: 3540
              },
              {
                keyword: '控股',
                total: 3327
              },
              {
                keyword: '王振华',
                total: 2303
              },
              {
                keyword: '女童',
                total: 1695
              },
              {
                keyword: '董事长',
                total: 1431
              },
              {
                keyword: '晓松',
                total: 1103
              },
              {
                keyword: '儿童',
                total: 827
              },
              {
                keyword: '刑拘',
                total: 819
              },
              {
                keyword: '儿童性',
                total: 697
              },
              {
                keyword: '董事会',
                total: 606
              },
              {
                keyword: '个例',
                total: 589
              },
              {
                keyword: '冰山一角',
                total: 586
              },
              {
                keyword: '零容忍',
                total: 582
              },
              {
                keyword: '内幕',
                total: 551
              },
              {
                keyword: '质押',
                total: 421
              },
              {
                keyword: '跌停',
                total: 405
              },
              {
                keyword: '知情人',
                total: 312
              },
              {
                keyword: '交易',
                total: 235
              },
              {
                keyword: '小天使',
                total: 178
              },
              {
                keyword: '伤害',
                total: 185
              }
            ]
          ],
          desc: ['到达顶峰', '开始衰退', '再次爆发', '到达顶峰', '结束时间']
        },
        neg_key_for_web_media: [
            {
                "keyword": "伊兹",
                "total": 2977
            },
            {
                "keyword": "刺杀",
                "total": 2912
            },
            {
                "keyword": "遇刺",
                "total": 2502
            },
            {
                "keyword": "约瑟夫",
                "total": 1763
            },
            {
                "keyword": "海地总统",
                "total": 1731
            },
            {
                "keyword": "哥伦比亚",
                "total": 1467
            },
            {
                "keyword": "总统",
                "total": 1277
            },
            {
                "keyword": "嫌犯",
                "total": 1011
            },
            {
                "keyword": "暗杀",
                "total": 695
            },
            {
                "keyword": "嫌疑人",
                "total": 667
            },
            {
                "keyword": "武装",
                "total": 648
            },
            {
                "keyword": "雇佣兵",
                "total": 553
            },
            {
                "keyword": "身亡",
                "total": 506
            },
            {
                "keyword": "被捕",
                "total": 492
            },
            {
                "keyword": "警方",
                "total": 450
            },
            {
                "keyword": "9日",
                "total": 406
            },
            {
                "keyword": "参议院",
                "total": 390
            },
            {
                "keyword": "海外网",
                "total": 367
            },
            {
                "keyword": "抓捕",
                "total": 366
            },
            {
                "keyword": "7月8日",
                "total": 358
            },
            {
                "keyword": "美国人",
                "total": 358
            },
            {
                "keyword": "7日",
                "total": 355
            },
            {
                "keyword": "逮捕",
                "total": 348
            },
            {
                "keyword": "第一夫人",
                "total": 319
            },
            {
                "keyword": "总理",
                "total": 315
            },
            {
                "keyword": "美国",
                "total": 313
            },
            {
                "keyword": "蒂娜",
                "total": 307
            },
            {
                "keyword": "索拉",
                "total": 303
            },
            {
                "keyword": "反对派",
                "total": 302
            },
            {
                "keyword": "委内瑞拉",
                "total": 301
            },
            {
                "keyword": "公职人员",
                "total": 288
            },
            {
                "keyword": "哥伦比亚人",
                "total": 270
            },
            {
                "keyword": "拜登",
                "total": 266
            },
            {
                "keyword": "下令",
                "total": 260
            },
            {
                "keyword": "总统夫人",
                "total": 255
            },
            {
                "keyword": "CTU",
                "total": 243
            },
            {
                "keyword": "7月7日",
                "total": 242
            },
            {
                "keyword": "团伙",
                "total": 241
            },
            {
                "keyword": "莱昂",
                "total": 237
            },
            {
                "keyword": "政局",
                "total": 215
            },
            {
                "keyword": "8日",
                "total": 214
            },
            {
                "keyword": "任期",
                "total": 211
            },
            {
                "keyword": "10日",
                "total": 197
            },
            {
                "keyword": "杜克",
                "total": 193
            },
            {
                "keyword": "任命",
                "total": 188
            },
            {
                "keyword": "核心成员",
                "total": 178
            },
            {
                "keyword": "迈阿密",
                "total": 171
            },
            {
                "keyword": "政变",
                "total": 166
            },
            {
                "keyword": "普萨基",
                "total": 164
            },
            {
                "keyword": "巴尔加斯",
                "total": 163
            }
        ],
        neg_key_for_weibo: [
            {
                "keyword": "海地总统",
                "total": 875
            },
            {
                "keyword": "刺杀",
                "total": 602
            },
            {
                "keyword": "伊兹",
                "total": 528
            },
            {
                "keyword": "遇刺",
                "total": 268
            },
            {
                "keyword": "刺杀总统",
                "total": 252
            },
            {
                "keyword": "哥伦比亚人",
                "total": 220
            },
            {
                "keyword": "嫌犯",
                "total": 183
            },
            {
                "keyword": "美国特工",
                "total": 132
            },
            {
                "keyword": "暗杀",
                "total": 112
            },
            {
                "keyword": "贫穷落后",
                "total": 72
            },
            {
                "keyword": "刺杀行动",
                "total": 66
            },
            {
                "keyword": "傻逼",
                "total": 65
            },
            {
                "keyword": "美国缉毒局",
                "total": 56
            },
            {
                "keyword": "线人",
                "total": 55
            },
            {
                "keyword": "卡斯特罗",
                "total": 51
            },
            {
                "keyword": "关押",
                "total": 47
            },
            {
                "keyword": "谋杀",
                "total": 44
            },
            {
                "keyword": "袭击者",
                "total": 43
            },
            {
                "keyword": "太子港",
                "total": 42
            },
            {
                "keyword": "黑人",
                "total": 39
            },
            {
                "keyword": "玛蒂",
                "total": 38
            },
            {
                "keyword": "莱昂",
                "total": 36
            },
            {
                "keyword": "警察局长",
                "total": 34
            },
            {
                "keyword": "京东客服",
                "total": 33
            },
            {
                "keyword": "白人",
                "total": 33
            },
            {
                "keyword": "嫌疑犯",
                "total": 32
            },
            {
                "keyword": "主使",
                "total": 31
            },
            {
                "keyword": "保姆纵火案",
                "total": 31
            },
            {
                "keyword": "充电宝",
                "total": 31
            },
            {
                "keyword": "巴尔加斯",
                "total": 27
            },
            {
                "keyword": "哥伦比亚",
                "total": 26
            },
            {
                "keyword": "主谋",
                "total": 25
            },
            {
                "keyword": "总统夫人",
                "total": 25
            },
            {
                "keyword": "受雇",
                "total": 22
            },
            {
                "keyword": "酷刑",
                "total": 22
            },
            {
                "keyword": "刺到",
                "total": 21
            },
            {
                "keyword": "鲈鳗",
                "total": 21
            },
            {
                "keyword": "代表处",
                "total": 20
            },
            {
                "keyword": "击毙",
                "total": 20
            },
            {
                "keyword": "坏笑",
                "total": 20
            },
            {
                "keyword": "孤烟",
                "total": 20
            },
            {
                "keyword": "洋务",
                "total": 20
            },
            {
                "keyword": "缉毒特工",
                "total": 20
            },
            {
                "keyword": "7月8日",
                "total": 19
            },
            {
                "keyword": "联邦调查局",
                "total": 19
            },
            {
                "keyword": "反水",
                "total": 18
            },
            {
                "keyword": "多党",
                "total": 18
            },
            {
                "keyword": "亚裔",
                "total": 17
            },
            {
                "keyword": "枪手",
                "total": 17
            },
            {
                "keyword": "游街",
                "total": 17
            }
        ],
        neg_key_for_weixin: [
          {
            keyword: '王振华',
            total: 5044
          },
          {
            keyword: '女童',
            total: 4868
          },
          {
            keyword: '新城',
            total: 4537
          },
          {
            keyword: '控股',
            total: 4317
          },
          {
            keyword: '周某',
            total: 1986
          },
          {
            keyword: '董事长',
            total: 1976
          },
          {
            keyword: '晓松',
            total: 1647
          },
          {
            keyword: '周某某',
            total: 1514
          },
          {
            keyword: '王某某',
            total: 1445
          },
          {
            keyword: '王某',
            total: 1153
          },
          {
            keyword: '上市公司',
            total: 1107
          },
          {
            keyword: '儿童',
            total: 1086
          },
          {
            keyword: '警方',
            total: 946
          },
          {
            keyword: '普陀',
            total: 906
          },
          {
            keyword: '幼女',
            total: 822
          },
          {
            keyword: '港股',
            total: 652
          },
          {
            keyword: '董事',
            total: 581
          },
          {
            keyword: '依法严惩',
            total: 516
          },
          {
            keyword: '儿童性',
            total: 427
          },
          {
            keyword: '控股集团',
            total: 413
          },
          {
            keyword: '未成年人',
            total: 388
          },
          {
            keyword: '性侵害',
            total: 371
          },
          {
            keyword: '强制措施',
            total: 361
          },
          {
            keyword: '董事会',
            total: 353
          },
          {
            keyword: '熟人',
            total: 338
          },
          {
            keyword: '恋童癖',
            total: 316
          },
          {
            keyword: '常州',
            total: 312
          },
          {
            keyword: '新民晚报',
            total: 311
          },
          {
            keyword: '人渣',
            total: 310
          },
          {
            keyword: '受害者',
            total: 294
          },
          {
            keyword: '犯罪嫌疑人',
            total: 288
          },
          {
            keyword: '强奸罪',
            total: 286
          },
          {
            keyword: '刑事拘留',
            total: 281
          },
          {
            keyword: 'A股',
            total: 280
          },
          {
            keyword: '女孩',
            total: 268
          },
          {
            keyword: '股份',
            total: 267
          },
          {
            keyword: '江苏人',
            total: 263
          },
          {
            keyword: '有期徒刑',
            total: 260
          },
          {
            keyword: '犯罪',
            total: 260
          },
          {
            keyword: '市值',
            total: 258
          },
          {
            keyword: '上海市公安局',
            total: 247
          },
          {
            keyword: '七色光',
            total: 241
          },
          {
            keyword: '新闻',
            total: 227
          },
          {
            keyword: '跌停',
            total: 217
          },
          {
            keyword: '刑拘',
            total: 212
          },
          {
            keyword: '犯罪者',
            total: 207
          },
          {
            keyword: '江苏省人大',
            total: 185
          },
          {
            keyword: '案件',
            total: 182
          },
          {
            keyword: '罪犯',
            total: 181
          },
          {
            keyword: '董秘',
            total: 181
          }
        ],
        neg_key_for_forum: [
          {
            keyword: '新城',
            total: 649
          },
          {
            keyword: '女童',
            total: 524
          },
          {
            keyword: '控股',
            total: 503
          },
          {
            keyword: '王振华',
            total: 384
          },
          {
            keyword: '董事长',
            total: 294
          },
          {
            keyword: '王某',
            total: 273
          },
          {
            keyword: '周某',
            total: 233
          },
          {
            keyword: '强制措施',
            total: 192
          },
          {
            keyword: '晓松',
            total: 173
          },
          {
            keyword: '女孩',
            total: 157
          },
          {
            keyword: '上市公司',
            total: 148
          },
          {
            keyword: '江苏省人大',
            total: 127
          },
          {
            keyword: '王某某',
            total: 125
          },
          {
            keyword: '周某某',
            total: 108
          },
          {
            keyword: '母亲',
            total: 96
          },
          {
            keyword: '撕裂伤',
            total: 87
          },
          {
            keyword: '普陀',
            total: 75
          },
          {
            keyword: '董事会',
            total: 63
          },
          {
            keyword: '警方',
            total: 61
          },
          {
            keyword: '儿童',
            total: 59
          },
          {
            keyword: '股价',
            total: 59
          },
          {
            keyword: '全国工商联房地产商会',
            total: 52
          },
          {
            keyword: '幼女',
            total: 52
          },
          {
            keyword: '质押',
            total: 45
          },
          {
            keyword: '徐州人',
            total: 43
          },
          {
            keyword: '董事',
            total: 41
          },
          {
            keyword: '内幕',
            total: 39
          },
          {
            keyword: '新民晚报',
            total: 37
          },
          {
            keyword: '验伤',
            total: 37
          },
          {
            keyword: '江苏',
            total: 35
          },
          {
            keyword: '市值',
            total: 32
          },
          {
            keyword: '犯罪嫌疑人',
            total: 31
          },
          {
            keyword: '涉案人',
            total: 30
          },
          {
            keyword: '上海市公安局',
            total: 27
          },
          {
            keyword: '未成年人',
            total: 27
          },
          {
            keyword: '港股',
            total: 26
          },
          {
            keyword: '一季度',
            total: 25
          },
          {
            keyword: '刑事拘留',
            total: 25
          },
          {
            keyword: '刑拘',
            total: 24
          },
          {
            keyword: '德润',
            total: 24
          },
          {
            keyword: '轻伤',
            total: 23
          },
          {
            keyword: '七色光',
            total: 22
          },
          {
            keyword: '常州',
            total: 21
          },
          {
            keyword: '江苏人',
            total: 21
          },
          {
            keyword: '犯罪',
            total: 21
          },
          {
            keyword: '知情人',
            total: 21
          },
          {
            keyword: '罪恶',
            total: 21
          },
          {
            keyword: '性侵害',
            total: 19
          },
          {
            keyword: '交易',
            total: 18
          },
          {
            keyword: '人寿',
            total: 18
          }
        ],
        summary: [
          {
            keyword: '',
            text:
              '本方案于2021-07-07至2021-08-01期间，采集了关于 海地总统 && 刺杀 的28459条信息。'
          },
          {
            keyword: '总体情绪',
            text: '含10453条正面信息（占36.7 %）、18006条负面信息（占63.3 %）'
          },
          {
            keyword: '负面来源',
            text: '主要来自：腾讯网,今日头条,微博,搜狐,新浪网'
          },
          {
            keyword: '负面影响力',
            text: '覆盖639个网媒、1826个微博用户、34个论坛频道'
          },
          {
            keyword: '正面来源',
            text: '主要来自：微博,腾讯网,今日头条,手机江西台,搜狐'
          },
          {
            keyword: '正面影响力：',
            text: '覆盖345个网媒、4522个微博用户、5个论坛频道'
          }
        ],
        summary_em_dis: [
          {
            keyword: '',
            text:
              '整体信息包含含10453条正面信息（占36.70%）、18006条负面信息（占63.30%）'
          },
          {
            keyword: '',
            text:
              '在所有媒体类型的文章中, 自媒体号含正面信息的占比最高(25.42%),主要包含 腾讯网,今日头条,新浪网,搜狐,一点资讯'
          },
          {
            keyword: '',
            text:
              '在所有媒体类型的文章中, 微博含负面信息的占比最高(22.30%),主要包含 心碎葬海丶宾康,围脖电影院,精选视频集锦,电影全搜罗,2003执子之手'
          },
          {
            keyword: '正面舆论类型',
            text: ['用户自发型']
          },
          {
            keyword: '负面舆论类型',
            text: ['用户自发型']
          }
        ],
        summary_em_neg: [
          {
            keyword: '',
            text:
              '深入剖析负面信息后，发现负面信息于2021-07-08的占比最高，主要来自媒体 中国青年网,环球网,光明网,海外网,新京报。主要传播日为 2021-07-08。最值得关注的是 自媒体号于2021-07-08的信息。'
          }
        ],
        summary_em_tre: [
          {
            keyword: '',
            text:
              '从整体情感走势来看，负面情绪于2021-07-08达到最高峰，正面情绪于2021-07-08达到最高峰。各媒体类型中，主要需要关注的是自媒体号于2021-07-08的信息高峰。'
          },
          {
            keyword: '正面热度指数',
            text: ['超高热度']
          },
          {
            keyword: '负面热度指数',
            text: ['超高热度']
          }
        ]
      },
      calendarMonth: { left: { month: null, data: [] }, right: { month: null, data: [] } },
      colors: ['#8ABAF8', '#E9A142', '#599E65', '#4478D2'], //網媒
      loading: true,
      created: ''
    }
  },
  mounted() {
    this.loadData()
    this.$nextTick(() => {
      let _this = this
      window.onscroll = function() {
        let height = document.documentElement.scrollTop + 75
        if (height > 200) {
          $('#emotion-tab').addClass('scroll-tab')
          $('#emotion-tab').addClass('scroll')
        } else {
          $('#emotion-tab').removeClass('scroll')
          $('#emotion-tab').removeClass('scroll-tab')
        }
        let b = window.document.getElementById('lyfx').offsetTop
        let c = window.document.getElementById('qsfx').offsetTop
        let d = window.document.getElementById('fmxxpx').offsetTop
        if (height < b) {
          _this.switchTab(0)
        } else if (height >= b && height < c) {
          _this.switchTab(1)
        } else if (height >= c && height < d) {
          _this.switchTab(2)
        } else {
          _this.switchTab(3)
        }
      }
    })
  },
  methods: {
    findPointImg(name) {
      return findPointImg(name)
    },
    isArray(data) {
      return _.isArray(data)
    },
    switchTab(index, scrollTo = false) {
      if (scrollTo) {
        var h = 0
        switch (index) {
          case 0:
            document.documentElement.scrollTop = h
            break
          case 1:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('lyfx').offsetTop - h
            break
          case 2:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('qsfx').offsetTop - h
            break
          case 3:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('fmxxpx').offsetTop - h
            break
        }
      }
      $('.flex-container a').removeClass('selected')
      $('.flex-container a:eq(' + index + ')').addClass('selected')
    },
    loadData() {
      this.title = '情感分析'
      this.created = '2019-07-30 16:06:44'
      this.loading = false
      this.handleData()
      this.$nextTick(() => {
        this.createChart()
      })
    },
    handleData() {
      var _this = this
      this.data.neg_num_top = this.data.neg_num_top.map(function(item, index) {
        item.rate = (item.total / _this.data.neg_num_top[0].total).toFixed(2) * 100
        return item
      })
      this.data.neg_tre_date.range = this.data.neg_tre_date.range.map((v, k) => {
        let ymd = v.split('-')
        let maxDay = new Date(ymd[0], ymd[1], 0).getDate()
        return k == 0 ? ymd[0] + '-' + ymd[1] + '-' + '01' : ymd[0] + '-' + ymd[1] + '-' + maxDay
      })
      if (this.data.pos_vs_neg.isIncubation) {
        let posWeight = 0
        let negWeight = 0
        this.data.pos_vs_neg.timeline.map(item => {
          posWeight += parseInt(item['weight_pos'] * 100)
          negWeight += parseInt(item['weight_neg'] * 100)
        })
        this.data.pos_vs_neg.pos_weight = 30
        this.data.pos_vs_neg.neg_weight = 70
        this.data.pos_vs_neg.pos_neg_diff = Math.abs(
          this.data.pos_vs_neg.neg_weight - this.data.pos_vs_neg.pos_weight
        )
      }
    },
    createChart() {
      let loopKeys = [
        'em_por',
        'neg_dis_for_cat',
        'neg_dis_for_web_media',
        'neg_dis_for_weibo_type',
        'neg_dis_for_weibo_user'
      ]
      let lineKeys = [
        'em_dis_for_cat',
        'em_dis_for_web_media',
        'em_dis_for_weibo_type',
        'em_dis_for_weibo_user'
      ]
      let trendKeys = [
        'em_tre',
        'em_tre_for_web_media',
        'em_tre_for_weibo',
        'em_tre_for_weixin',
        'neg_tre',
        'neg_tre_for_web_media',
        'neg_tre_for_weibo_type',
        'neg_tre_for_weibo_user'
      ]
      let wordsKeys = [
        'neg_key',
        'neg_key_for_web_media',
        'neg_key_for_weibo',
        'neg_key_for_weixin',
        'neg_key_for_forum'
      ]
      let data = this.data
      for (let key in data) {
        if (loopKeys.indexOf(key) !== -1) {
          this.loopChart(data[key], key)
        } else if (lineKeys.indexOf(key) !== -1) {
          this.lineRateChart(data[key], key)
        } else if (trendKeys.indexOf(key) !== -1) {
          this.trendChart(data[key], key)
        } else if (wordsKeys.indexOf(key) !== -1) {
          this.wordCloudChart(data[key], key)
        }
      }
      // 整体信息情感日历 第一月
      var args = { month: data['em_por_date'].type[0], data: data['em_por_date'].data }
      this.calendarChart(args, 'em_por_date')
      this.pointChart(data['em_dis_for_media'], 'em_dis_for_media')
      this.pointChart(data['em_dis_for_media_por'], 'em_dis_for_media_por')
      this.pointCalendarChart(data['neg_tre_date'])
      this.wordHeatChart(data['neg_key_tre'])
      if (this.data.pos_vs_neg.isIncubation) {
        this.vsTrend()
      }
    },
    vsTrend() {
      // pos_vs_neg_trend
      let data = this.data['pos_vs_neg'].trend
      let keys = []
      let seriesData = []
      data.map(item => {
        keys.push(item.date)
        if (item.points) {
          seriesData.push({
            value: item.count,
            symbol: 'image://' + findPointImg(item.points[0]),
            name: item.points.join(',')
          })
        } else {
          seriesData.push({
            value: item.count,
            name: ''
          })
        }
      })
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          show: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: keys
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'line',
            stack: '总量',
            symbol: 'none',
            symbolSize: 20,
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: function(params) {
                  return params.data.name
                }
              }
            },
            data: seriesData
          }
        ]
      }
      let element = window.document.getElementById('pos_vs_neg_trend')
      let chart = globalCreateChart(element, option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    loopChart(data, id) {
      var name = null
      var legend = []
      var chartData = []
      var colors = ['#3479CF', '#55D9FF', '#74CCB1', '#3178d2'] //網媒
      var rateMap = []
      switch (id) {
        case 'em_por':
          name = '情感分布'
          legend = ['负面', '正面']
          colors = ['#FC5D73', '#555DFE']
          chartData = [
            { name: '负面', value: data.negative },
            { name: '正面', value: data.positive }
          ]
          var total = parseInt(data.negative) + parseInt(data.positive)
          rateMap = [
            ((data.negative / total) * 100).toFixed(2),
            ((data.positive / total) * 100).toFixed(2)
          ]
          break
        case 'neg_dis_for_cat':
          name = '媒体类型分布'
          legend = data.type
          var total = 0
          for (let i = 0; i < legend.length; i++) {
            total += data.data[i]
            let item = { name: legend[i], value: data.data[i], itemStyle: {} }
            let color = globalMediaColor(legend[i])
            if (color) item.itemStyle.color = color
            chartData.push(item)
          }
          for (let i = 0; i < data.data.length; i++) {
            rateMap.push(((data.data[i] / total) * 100).toFixed(2))
          }
          break
        case 'neg_dis_for_web_media':
          name = '网媒类型分布'
          legend = data.type
          var total = 0
          for (let i = 0; i < legend.length; i++) {
            total += data.data[i]
            chartData.push({ name: legend[i], value: data.data[i] })
          }
          for (let i = 0; i < data.data.length; i++) {
            rateMap.push(((data.data[i] / total) * 100).toFixed(2))
          }
          break
        case 'neg_dis_for_weibo_type':
          name = '微博类型分布'
          legend = data.type
          colors = ['#ffa237', '#009df6']
          chartData = [
            { name: data.type[0], value: data.data[0] },
            { name: data.type[1], value: data.data[1] }
          ]
          var total = parseInt(data.data[0]) + parseInt(data.data[1])
          rateMap = [
            ((data.data[0] / total) * 100).toFixed(2),
            ((data.data[1] / total) * 100).toFixed(2)
          ]
          break
        case 'neg_dis_for_weibo_user':
          name = '微博用户类型分布'
          colors = []
          legend = data.type
          var total = 0
          for (let i = 0; i < legend.length; i++) {
            total += data.data[i]
            chartData.push({ name: legend[i], value: data.data[i] })
          }
          for (let i = 0; i < data.data.length; i++) {
            rateMap.push(((data.data[i] / total) * 100).toFixed(2))
          }
          legend = legend.map((item, index) => {
            let temp = {
              name: item,
              textStyle: {
                color: '#ffa237'
              },
              itemGap: 20,
              icon: 'image:///static/img/v_red.png'
            }
            switch (item) {
              case '金V':
                colors.push('#c91d10')
                temp.textStyle.color = '#c91d10'
                temp.icon = 'image:///static/img/v_red.png'
                break
              case '黄V':
                colors.push('#fea700')
                temp.textStyle.color = '#fea700'
                temp.icon = 'image:///static/img/v_orange.png'
                break
              case '蓝V':
                colors.push('#09aaf7')
                temp.textStyle.color = '#09aaf7'
                temp.icon = 'image:///static/img/v_blue.png'
                break
              case '微博女郎':
                colors.push('#fc5d9a')
                temp.textStyle.color = '#fc5d9a'
                temp.icon = 'image:///static/img/v_pink.png'
                break
              case '达人':
                colors.push('#e8220e')
                temp.textStyle.color = '#e8220e'
                temp.icon = 'image:///static/img/v_talent_show.png'
                break
              default:
                colors.push('#0078d5')
                temp.textStyle.color = '#0078d5'
                temp.icon = 'circle'
                break
            }
            return temp
          })
          break
      }
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c}({d}%)'
        },
        legend: {
          itemWidth: 10,
          itemHeight: 10,
          orient: 'vertical',
          left: '10px',
          bottom: '20px',
          data: legend,
          icon: 'circle',
          itemGap: 5,
          formatter: function(name) {
            let legendMap = []
            _(legend).forEach((item, index) => {
              if (typeof item == 'string') {
                legendMap.push(item)
              } else if (typeof item == 'object') {
                legendMap.push(item.name)
              }
            })
            let index = legendMap.indexOf(name)
            return isNaN(rateMap[index]) ? name + '0%' : name + ' ' + rateMap[index] + '%'
          }
        },
        color: colors,
        series: [
          {
            name: name,
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: chartData
          }
        ]
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    lineRateChart(data, id) {
      let negativeData = []
      let positiveData = []
      for (let i = 0; i < data.data.length; i++) {
        negativeData.push(data.data[i].negative)
        positiveData.push(data.data[i].positive)
      }
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '{b} <br />{a}：{c}%<br />{a1}：{c1}%'
        },
        legend: {
          data: ['负面', '正面'],
          icon: 'circle',
          itemGap: 20,
          itemWidth: 10,
          itemHeight: 10,
          selectedMode: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10px',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value}%'
          }
        },
        yAxis: {
          type: 'category',
          data: data.type
        },
        series: [
          {
            name: '负面',
            type: 'bar',
            stack: '总量',
            itemStyle: {
              normal: {
                color: '#FC5D73',
                barBorderRadius: [10, 0, 0, 10]
              }
            },
            data: negativeData
          },
          {
            name: '正面',
            type: 'bar',
            stack: '总量',
            barWidth: 10,
            itemStyle: {
              normal: {
                color: '#555DFE',
                barBorderRadius: [0, 10, 10, 0]
              }
            },
            data: positiveData
          }
        ]
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    calendarChart(data, id) {
      // data = {month: 2018-12-07, data: []}
      let echarts = globalGetEcharts()
      this.calculationMonth(data.month)
      this.$nextTick(() => {
        var cellSize = [35, 35]
        var pieRadius = 7
        function getPieSeries(scatterData, chart) {
          return echarts.util.map(scatterData, function(item, index) {
            let temp = [item.date, item.total]
            var center = chart.convertToPixel('calendar', temp)
            return {
              id: index + 'pie',
              type: 'pie',
              center: center,
              label: {
                normal: {
                  formatter: '{c}',
                  position: 'inside',
                  fontSize: 9,
                  show: false
                }
              },
              color: ['#555DFE', '#FC5D73'],
              radius: pieRadius,
              data: [
                { name: '正面', value: item.positive },
                { name: '负面', value: item.negative }
              ]
            }
          })
        }
        let scatterData = data.data.map(function(item, index) {
          return [item.date, item.total]
        })
        let option = {
          tooltip: {
            formatter: '{b}：{c}%'
          },
          legend: {
            data: ['正面', '负面'],
            bottom: 0,
            icon: 'circle',
            itemWidth: 10,
            itemHeight: 10,
            itemGap: 20,
            show: false
          },
          color: ['#555DFE', '#FC5D73'],
          calendar: {
            top: 'middle',
            left: 'center',
            orient: 'vertical',
            cellSize: cellSize,
            yearLabel: {
              show: false,
              textStyle: {
                fontSize: 30
              }
            },
            dayLabel: {
              margin: 0,
              firstDay: 1,
              nameMap: ['日', '一', '二', '三', '四', '五', '六']
            },
            monthLabel: {
              show: false
            },
            range: [data.month]
          },
          series: [
            {
              id: 'label',
              type: 'scatter',
              coordinateSystem: 'calendar',
              symbolSize: 1,
              label: {
                normal: {
                  show: true,
                  formatter: function(params) {
                    return echarts.format.formatTime('dd', params.value[0])
                  },
                  offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
                  textStyle: {
                    color: '#000',
                    fontSize: 9
                  }
                }
              },
              data: scatterData
            }
          ]
        }
        let element = window.document.getElementById(id)
        var chart = globalCreateChart(element, option, true)
        chart.setOption({ series: getPieSeries(data.data, chart) })
      })
    },
    pointChart(data, id) {
      let option = {
        tooltip: {
          trigger: 'item'
        },
        xAxis: { name: '正面信息数', bottom: '15px', splitLine: { show: false } },
        yAxis: { name: '负面信息数', splitLine: { show: false } },
        grid: { right: '80px', bottom: '30px' },
        legend: {
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20,
          data: data.type
        },
        color: this.colors,
        series: []
      }
      if (id == 'em_dis_for_media_por') {
        option.xAxis.axisLabel = {
          show: true,
          interval: 'auto',
          formatter: '{value} %'
        }
        option.yAxis.axisLabel = {
          show: true,
          interval: 'auto',
          formatter: '{value} %'
        }
        option.tooltip.formatter = function(params) {
          return (
            params.seriesName +
            '：' +
            params.value[2] +
            '<br />正面：' +
            params.value[0] +
            '%' +
            '<br />负面：' +
            params.value[1] +
            '%'
          )
        }
      } else {
        option.tooltip.formatter = function(params) {
          return (
            params.seriesName +
            '：' +
            params.value[2] +
            '<br />正面：' +
            params.value[0] +
            '<br />负面：' +
            params.value[1]
          )
        }
      }
      let seriesData = []
      for (let i = 0; i < data.data.length; i++) {
        let series = {
          name: data.type[i],
          symbolSize: 13,
          label: {
            emphasis: {
              show: true,
              formatter: function(param) {
                return param.data[2]
              },
              position: 'top'
            }
          },
          itemStyle: {},
          data: [],
          type: 'scatter'
        }
        let color = globalMediaColor(data.type[i])
        if (color) series.itemStyle.color = color
        _(data.data[i]).forEach((item, index) => {
          item.value.push(item.name)
          series.data.push(item.value)
        })
        seriesData.push(series)
      }
      option.series = seriesData
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
    },
    trendChart(data, id) {
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          top: '5px',
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '70px',
          bottom: '10px',
          width: '90%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data.keys
        },
        yAxis: { type: 'value' },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          }
        ],
        color: ['#ed3815', '#ffa237 ', '#2fb0f8 ', '#bc1718 ', '#fd4e91', '0279d6'],
        series: []
      }
      let npKeys = ['em_tre', 'em_tre_for_web_media', 'em_tre_for_weibo', 'em_tre_for_weixin'] // 正负面数据类型
      let nkeys = ['neg_tre', 'neg_tre_for_web_media', 'neg_tre_for_weibo_user'] // 负面数据媒体类型
      let weiboKey = 'neg_tre_for_weibo_type' // 负面数据微博 转发 : 原发
      let seriesData = []
      let echarts = globalGetEcharts()
      var _this = this
      if (npKeys.indexOf(id) !== -1) {
        seriesData.push({
          name: '负面',
          type: 'line',
          smooth: true,
          color: '#FC5D73',
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FC5D73'
                },
                {
                  offset: 1,
                  color: '#fff'
                }
              ])
            }
          },
          data: data.negative
        })
        seriesData.push({
          name: '正面',
          type: 'line',
          color: '#555DFE',
          smooth: true,
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#555DFE'
                },
                {
                  offset: 1,
                  color: '#fff'
                }
              ])
            }
          },
          data: data.positive
        })
      } else if (nkeys.indexOf(id) !== -1) {
        _(data.data).forEach((item, index) => {
          item.type = 'line'
          item.smooth = true
          item.areaStyle = { normal: {} }
          if (id == 'neg_tre_for_weibo_user') {
            switch (item.name) {
              case '金V':
                item.color = '#c91d10'
                item.areaStyle = {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#c91d10'
                      },
                      {
                        offset: 1,
                        color: '#fff'
                      }
                    ])
                  }
                }
                break
              case '黄V':
                item.color = '#fea700'
                item.areaStyle = {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#fea700'
                      },
                      {
                        offset: 1,
                        color: '#fff'
                      }
                    ])
                  }
                }
                break
              case '蓝V':
                item.color = '#09aaf7'
                item.areaStyle = {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#09aaf7'
                      },
                      {
                        offset: 1,
                        color: '#fff'
                      }
                    ])
                  }
                }
                break
              case '微博女郎':
                item.color = '#fc5d9a'
                item.areaStyle = {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#fc5d9a'
                      },
                      {
                        offset: 1,
                        color: '#fff'
                      }
                    ])
                  }
                }
                break
              case '达人':
                item.color = '#e8220e'
                item.areaStyle = {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#e8220e'
                      },
                      {
                        offset: 1,
                        color: '#fff'
                      }
                    ])
                  }
                }
                break
              default:
                item.color = '#0078d5'
                item.areaStyle = {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#0078d5'
                      },
                      {
                        offset: 1,
                        color: '#fff'
                      }
                    ])
                  }
                }
                break
            }
          } else if (id == 'neg_tre') {
            let color = globalMediaColor(item.name)
            if (color) {
              item.color = color
              item.areaStyle = {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: color
                    },
                    {
                      offset: 1,
                      color: '#fff'
                    }
                  ])
                }
              }
            } else {
              if (index < _this.colors.length) {
                item.color = _this.colors[index]
                item.areaStyle = {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: item.color
                      },
                      {
                        offset: 1,
                        color: '#fff'
                      }
                    ])
                  }
                }
              }
            }
          } else {
            if (index < _this.colors.length) {
              item.color = _this.colors[index]
              item.areaStyle = {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: item.color
                    },
                    {
                      offset: 1,
                      color: '#fff'
                    }
                  ])
                }
              }
            }
          }
          seriesData.push(item)
        })
        if (id == 'neg_tre_for_weibo_user') {
          let legend = data.type.map((type, index) => {
            let temp = {
              name: type,
              textStyle: {
                color: '#ffa237'
              },
              itemGap: 20,
              icon: 'image:///static/img/v_red.png'
            }
            switch (type) {
              case '金V':
                temp.textStyle.color = '#c91d10'
                temp.icon = 'image:///static/img/v_red.png'
                break
              case '黄V':
                temp.textStyle.color = '#fea700'
                temp.icon = 'image:///static/img/v_orange.png'
                break
              case '蓝V':
                temp.textStyle.color = '#09aaf7'
                temp.icon = 'image:///static/img/v_blue.png'
                break
              case '微博女郎':
                temp.textStyle.color = '#fc5d9a'
                temp.icon = 'image:///static/img/v_pink.png'
                break
              case '达人':
                temp.textStyle.color = '#e8220e'
                temp.icon = 'image:///static/img/v_talent_show.png'
                break
              default:
                temp.textStyle.color = '#0078d5'
                temp.icon = 'circle'
                break
            }
            return temp
          })
          option.legend.data = legend
          option.legend.itemWidth = 10
          option.legend.itemHeight = 10
        }
      } else if (id == weiboKey) {
        seriesData.push({
          name: '原发',
          type: 'line',
          color: '#ffa237',
          smooth: true,
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#ffa237'
                },
                {
                  offset: 1,
                  color: '#fff'
                }
              ])
            }
          },
          data: data.forward
        })
        seriesData.push({
          name: '转发',
          type: 'line',
          smooth: true,
          color: '#009df6',
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#009df6'
                },
                {
                  offset: 1,
                  color: '#fff'
                }
              ])
            }
          },
          data: data.original
        })
      }
      option.series = seriesData
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
    },
    pointCalendarChart(data) {
      let chartData = data.data
      let top10Data = chartData
        .sort(function(a, b) {
          return b[1] - a[1]
        })
        .slice(0, 10)
      let option = {
        backgroundColor: '#fff',
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '30',
          data: ['负面信息数', 'Top 10']
        },
        calendar: [],
        series: []
      }
      let range = data.range
      let minYear = range[0].split('-')[0]
      let maxYear = range[1].split('-')[0]
      if (minYear != maxYear) {
        let date = new Date(minYear, 12, 0)
        let minRange = [range[0], minYear + '-12-' + date.getDate()]
        let maxRange = [maxYear + '-01-01', range[1]]
        option.calendar.push({
          top: 100,
          left: 'center',
          range: minRange,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#000',
              width: 4,
              type: 'solid'
            }
          },
          yearLabel: {
            formatter: '{start}  1st',
            textStyle: {
              color: '#fff'
            }
          },
          itemStyle: {
            normal: {
              color: '#fff',
              borderWidth: 1,
              borderColor: '#111'
            }
          }
        })
        option.calendar.push({
          top: 340,
          left: 'center',
          range: maxRange,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#000',
              width: 4,
              type: 'solid'
            }
          },
          yearLabel: {
            formatter: '{start}  2nd',
            textStyle: {
              color: '#fff'
            }
          },
          itemStyle: {
            normal: {
              color: '#fff',
              borderWidth: 1,
              borderColor: '#111'
            }
          }
        })
        option.series = [
          {
            name: '负面信息数',
            type: 'scatter',
            coordinateSystem: 'calendar',
            data: chartData,
            symbolSize: function(val) {
              return (val[1] / top10Data[0][1]).toFixed(2) * 20
            },
            itemStyle: {
              normal: {
                color: 'red'
              }
            }
          },
          {
            name: '负面信息数',
            type: 'scatter',
            coordinateSystem: 'calendar',
            calendarIndex: 1,
            data: chartData,
            symbolSize: function(val) {
              return (val[1] / top10Data[0][1]).toFixed(2) * 20
            },
            itemStyle: {
              normal: {
                color: 'red'
              }
            }
          },
          {
            name: 'Top 10',
            type: 'effectScatter',
            coordinateSystem: 'calendar',
            calendarIndex: 1,
            data: top10Data,
            symbolSize: 20,
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            itemStyle: {
              normal: {
                color: '#f4e925',
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            zlevel: 1
          },
          {
            name: 'Top 10',
            type: 'effectScatter',
            coordinateSystem: 'calendar',
            data: top10Data,
            symbolSize: 20,
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            itemStyle: {
              normal: {
                color: '#f4e925',
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            zlevel: 1
          }
        ]
      } else {
        option.calendar.push({
          top: 230,
          left: 'center',
          range: range,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#000',
              width: 4,
              type: 'solid'
            }
          },
          yearLabel: {
            formatter: '{start}  1st',
            textStyle: {
              color: '#fff'
            }
          },
          itemStyle: {
            normal: {
              color: '#fff',
              borderWidth: 1,
              borderColor: '#111'
            }
          }
        })
        option.series = [
          {
            name: '负面信息数',
            type: 'scatter',
            coordinateSystem: 'calendar',
            data: chartData,
            symbolSize: function(val) {
              return (val[1] / top10Data[0][1]).toFixed(2) * 20
            },
            itemStyle: {
              normal: {
                color: 'red'
              }
            }
          },
          {
            name: 'Top 10',
            type: 'effectScatter',
            coordinateSystem: 'calendar',
            data: top10Data,
            symbolSize: 20,
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            itemStyle: {
              normal: {
                color: '#f4e925',
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            zlevel: 1
          }
        ]
      }
      let element = window.document.getElementById('neg_tre_date')
      var chart = globalCreateChart(element, option)
    },
    wordHeatChart(data) {
      var max = 0
      _(data.data).forEach((row, index) => {
        row.map((v, k) => {
          max = v.total > max ? v.total : max
        })
      })
      data.keys = data.keys.map((item, index) => {
        return { value: [item, data.desc[index] ? data.desc[index] : ''] }
      })
      let option = {
        tooltip: {
          position: 'top',
          formatter: function(params) {
            return params.seriesName + '：' + params.data[2]
          }
        },
        animation: false,
        grid: {
          bottom: '10px',
          left: '130px'
        },
        xAxis: {
          type: 'category',
          data: [],
          splitArea: {
            show: true
          },
          show: false
        },
        yAxis: {
          type: 'category',
          data: data.keys,
          splitArea: {
            show: true
          },
          axisLabel: {
            formatter: function(arg) {
              return '{a|' + arg[0] + '} {b|' + arg[1] + '}'
            },
            rich: {
              b: {
                backgroundColor: '#e3eefd',
                padding: 3
              }
            }
          }
        },
        visualMap: {
          min: 0,
          max: max,
          calculable: true,
          orient: 'horizontal',
          show: false,
          inRange: {
            color: ['#ffbeb2', '#EE000F']
          }
        },
        series: []
      }
      let seriesData = []
      _(data.data).forEach((item, index) => {
        _(item).forEach((sub_item, sub_index) => {
          if (sub_index > 19) return
          let temp = {
            name: sub_item.keyword,
            type: 'heatmap',
            data: [[sub_index, index, sub_item.total]],
            label: {
              normal: {
                show: true,
                formatter: sub_item.keyword
              }
            }
          }
          seriesData.push(temp)
        })
      })
      option.series = seriesData
      let element = window.document.getElementById('neg_key_tre')
      var chart = globalCreateChart(element, option)
    },
    wordCloudChart(data, id) {
      let option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '信息数',
            type: 'wordCloud',
            shape: 'circle',
            top: '10%',
            width: '100%',
            height: '85%',
            right: null,
            bottom: null,
            sizeRange: [12, 30],
            rotationRange: [-90, 90],
            rotationStep: 45,
            hape: 'pentagon',
            gridSize: 8,
            drawOutOfBound: false,
            textStyle: {
              normal: {
                fontFamily: 'sans-serif',
                //fontWeight: 'bold',
                color: function(params) {
                  // Random color
                  let color = ['#4fcfb0', '#238fff', '#ff4f65', '#f98d3b', '#febd2e']
                  let index = Math.floor(Math.random() * color.length)
                  return color[index]
                }
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            data: []
          }
        ]
      }
      let chartData = []
      _(data).forEach((item, index) => {
        chartData.push({ name: item.keyword, value: item.total })
      })
      option.series[0].data = chartData
      let element = window.document.getElementById(id)
      var chart = globalCreateChart(element, option)
    },
    calculationMonth(month) {
      let data = this.data['em_por_date']
      let maxIndex = parseInt(this.data['em_por_date'].type.length - 1)
      if (data.type.length <= 1) {
        this.calendarMonth = { left: { month: null, data: [] }, right: { month: null, data: [] } }
        return false
      }
      for (let i = 0; i < data.type.length; i++) {
        if (data.type[i] == month) {
          // 最小区间
          if (i == 0) {
            this.calendarMonth = {
              left: { month: null, data: [] },
              right: { month: data.type[1], data: data.data }
            }
            break
          }
          // 最大区间
          if (i == maxIndex) {
            let index = i - 1
            this.calendarMonth = {
              left: { month: data.type[index], data: data.data },
              right: { month: null, data: [] }
            }
            break
          }
          let lt = i - 1
          let gt = i + 1
          this.calendarMonth = {
            left: { month: data.type[lt], data: data.data },
            right: { month: data.type[gt], data: data.data }
          }
          break
        }
      }
    }
  }
}
</script>
<style scoped>
/* 按钮组 */
.btn_tab {
  margin: auto;
}
.btn_tab a {
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: rgba(237, 237, 237, 0.6);
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  display: block;
  padding: 0 73px;
  border: none;
  font-size: 14px;
}
.btn_tab a.selected {
  color: #fff;
  background: rgba(42, 103, 179, 1);
}
.fxbg.pt_0 {
  padding-top: 0;
}
/*flex-container */
.scroll-tab {
  position: fixed;
  z-index: 3000;
  left: auto;
  right: auto;
  top: 200px;
  margin: auto;
}
.scroll {
  top: 0px;
}
.scroll .fxbg {
  box-shadow: 0px 3px 13px 0px rgba(194, 194, 194, 0.2);
  padding: 10px 0;
}
.echarts_empty {
  height: 250px;
}
/*负面信息内容观点*/
.fmxxnrgd .mod-list .mod-list-title1 {
  width: 356px;
}
/*全部负面信息热门关键词*/
.gjcy {
  float: left;
  margin-top: 16px;
}
/*整体信息情感日历*/
.qgrl-chart {
  position: absolute;
  left: 120px;
  top: 0px;
  z-index: 10;
  width: calc(100% - 240px);
}
.qgrl {
  width: 100%;
  position: relative;
}
.qgrl .left,
.qgrl .right {
  text-align: center;
  width: 120px;
  line-height: 30px;
  position: absolute;
  z-index: 10;
  top: 95px;
}
.qgrl .left {
  left: 0;
}
.qgrl .right {
  right: 0;
}
.qgrl .el-icon-d-arrow-left,
.qgrl .el-icon-d-arrow-right {
  font-size: 20px;
  cursor: pointer;
}
.qgrl .el-icon-d-arrow-left:hover,
.qgrl .el-icon-d-arrow-right:hover {
  color: #2a67b3;
}
.logo h3 {
  font-size: 30px;
  line-height: 60px;
  color: #1d77d2;
}
/*时间轴*/
.demo {
  width: 1080px;
  margin: 20px auto;
  padding: 45px 0 0;
}
.main-timeline {
  position: relative;
}
.main-timeline:before,
.main-timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline:before {
  content: '';
  width: 3px;
  height: calc(100% - 50px);
  background: #bebebe;
  position: absolute;
  top: 0;
  left: 50%;
}
.main-timeline .timeline {
  width: 50%;
  float: left;
  position: relative;
  z-index: 1;
}
.main-timeline .timeline:before,
.main-timeline .timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline .timeline-content {
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline .timeline-content:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #bebebe;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 7px;
}
.main-timeline .circle {
  width: 450px;
  height: 90px;
  background: rgb(239, 244, 254);
  border-radius: 8px;
  float: left;
  position: relative;
  color: #000;
  margin-top: -35px;
}
.main-timeline .content {
  display: table;
  padding: 0 5px;
  position: relative;
}
.main-timeline .year {
  display: block;
  margin: 0 0 50px 0;
  border-radius: 7px;
  font-size: 12px;
  color: #fff;
  width: 77px;
  height: 19px;
  background-color: #2a67b3;
  border-radius: 4.5px;
  margin-top: -15px;
}
.main-timeline .icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #2a67b3;
  position: absolute;
  top: 0;
  right: -12px;
  display: inline-block;
}
.main-timeline .timeline:first-child .timeline-content:before {
  top: 7px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .circle {
  float: right;
}
.main-timeline .timeline:nth-child(even) {
  margin-top: 60px;
}
.main-timeline .timeline:nth-child(even) .timeline-content:before {
  top: 3px;
}
.main-timeline .timeline:nth-child(even) .year {
  margin-top: -20px;
}
.main-timeline .timeline:nth-child(even) .icon {
  left: -10px;
  right: 0;
}
.main-timeline .mod-list {
  padding: 8px 10px;
}
.main-timeline .mod-list li {
  width: 100%;
  line-height: 25px;
  height: 25px;
}
.main-timeline .mod-list .num {
  margin: 4px 10px 0 0;
}
.main-timeline .mod-list li a {
  width: 390px;
  text-align: left;
}
/*评分*/
.pk1,
.pk2,
.pk3 {
  width: 1120px;
  margin: 0 auto;
  height: 82px;
  overflow: hidden;
  background-image: url(/images/pk-01.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  line-height: 82px;
  color: #fff;
  text-align: center;
}
.pk1 {
  background-image: url(../../../assets/images/analysis/vs-01.png);
}
.pk2 {
  background-image: url(../../../assets/images/analysis/vs-02.png);
}
.pk3 {
  background-image: url(../../../assets/images/analysis/vs-03.png);
}
.pk1 p,
.pk2 p,
.pk3 p {
  float: left;
  font-weight: 600;
  color: #fff;
}
.pk1 .zm,
.pk2 .fm {
  width: 71%;
}
.pk1 .fm,
.pk2 .zm {
  width: 29%;
}
.pk3 .fm,
.pk3 .zm {
  width: 50%;
}
/*时间轴*/
.demo2 {
  width: 1120px;
  margin: 0 auto;
  padding-top: 45px;
}
.main-timeline2 {
  position: relative;
  padding-bottom: 10px;
}
.main-timeline2:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline2 .timeline {
  width: 50%;
  float: left;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.main-timeline2 .timeline:before,
.main-timeline2 .timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline2 .timeline-content {
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline2 .timeline-content:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #bebebe;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 20px;
}
.main-timeline2 .circle {
  width: 480px;
  float: left;
  position: relative;
  color: #000;
  text-align: left;
}
.main-timeline2 .timeline_title {
  background: rgb(239, 244, 254);
  border-radius: 8px;
  padding: 11px 20px 11px 10px;
  line-height: 25px;
  position: relative;
}
.main-timeline2 .timeline_title .img,
.main-timeline2 .mod-list .img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.main-timeline2 .timeline_title h4,
.main-timeline2 .timeline_title p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 433px;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 24px;
}
.main-timeline2 .timeline_title p span {
  padding-right: 10px;
}
.main-timeline2 .timeline_title p .bd {
  padding-left: 10px;
}
.main-timeline2 .timeline_title dl {
  overflow: hidden;
  margin-bottom: 0;
}
.main-timeline2 .timeline_title dd,
.main-timeline2 .timeline_title a,
.main-timeline2 .timeline_title .bd {
  float: left;
  font-size: 12px;
  color: #474747;
}
.main-timeline2 .timeline_title h4 a {
  float: none;
}
.main-timeline2 .timeline_title .bd {
  padding: 0 15px;
}
.main-timeline2 .timeline_title dd:last-child > .bd {
  display: none;
}
.main-timeline2 .mod-list {
  padding: 8px 20px 8px 10px;
  background: rgb(239, 244, 254);
  border-radius: 8px;
  margin-top: 10px;
  position: relative;
  box-sizing: border-box;
}
.main-timeline2 .mod-list li {
  width: 100%;
  line-height: 30px;
  height: 30px;
}
.main-timeline2 .num {
  margin-right: 10px;
}
.main-timeline2 .mod-list li a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
.main-timeline2 .mod-list li .title {
  width: 320px;
}
.main-timeline2 .mod-list li .site {
  width: 80px;
  padding-left: 10px;
  color: #474747;
  float: left;
}
.main-timeline2 .site span {
  padding-right: 10px;
}
.main-timeline2 .content {
  margin: 0;
  display: table;
  padding: 0 5px;
  position: absolute;
  top: 0;
  left: 500px;
  z-index: 10;
  text-align: center;
  width: 112px;
}
.main-timeline2 .year {
  display: block;
  border-radius: 7px;
  font-size: 14px;
  color: #fff;
  width: 112px;
  height: 40px;
  line-height: 40px;
  background-color: #2a67b3;
  border-radius: 8px;
}
.main-timeline2 .point {
  height: 60px;
  line-height: 20px;
  text-align: center;
  margin: 10px auto;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
}
.main-timeline2 .point img {
  margin-right: 8px;
  vertical-align: top;
}
.main-timeline2 .point span {
  font-size: 14px;
}
.main-timeline2 h5 {
  width: 36px;
  height: 123px;
  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(199, 199, 199, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 18px;
  font-size: 20px;
  color: rgba(0, 103, 177, 1);
  line-height: 30px;
  text-align: center;
  padding-top: 13px;
  margin: 10px auto;
  margin-block-start: 0;
  margin-block-end: 0;
}
.main-timeline2 .start {
  line-height: 19px;
}
.main-timeline2 .timeline:nth-of-type(3),
.main-timeline2 .timeline:nth-of-type(3) .circle {
  float: right;
}
.demo2 .main-timeline2:last-child,
.main-timeline2:last-child .timeline {
  margin: 0;
}
.demo2 .item {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.demo2 .main-timeline2 .item:nth-child(2n) .timeline-content:before {
  display: none;
}
.item_list .timeline-content:before {
  display: none;
}
.demo2 .main-timeline2:last-child {
  padding-bottom: 20px;
}
</style>
